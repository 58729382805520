import React, { useEffect, useState } from "react";
import Header from './../components/Layout/Header';
import Footer from './../components/Layout/Footer';
import Filter from "../components/Filter";
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import CardEquipment from '../components/Home/CardEquipment';
import CardEquipmentSkeleton from "../components/Home/CardEquipmentSkeleton";
import { useProducts, useProductsFilter } from "../utils/services/queries";

const Equipments = (props) => {
    const navigate = useNavigate();
    const { mutate, isLoading } = useProducts();
    const { mutate: mutateFilter, isLoading: isLoadingFilter } = useProductsFilter();
    const [data, setData] = useState([]);
    //Número atual da página
    const [page, setPage] = useState(1);

    //GETS
    const query = new URLSearchParams(window.location.search);
    const valueSearch = query.get('search')
    const valueCategory = query.get('category')
    const valueBrand = query.get('brand')

    //Filter
    const [filter, setFilter] = useState(0);
    const [perPage, setPerPage] = useState(12);
    const [subcategoryFilter, setSubcategoryFilter] = useState((valueCategory > 0) ? [valueCategory] : []);
    const [manufacturerFilter, setManufacturerFilter] = useState((valueBrand) ? [valueBrand] : []);
    const [modelFilter, setModelFilter] = useState([]);

    const [filterMobile, setFilterMobile] = useState(false);

    const pageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    };

    const filterChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (event.target.getAttribute('data-filter') === 'subcategory') {
            const idFilter = event.target.getAttribute('data-filter-id');
            if (value)
                setSubcategoryFilter([...subcategoryFilter, idFilter]);
            else
                setSubcategoryFilter(subcategoryFilter.filter(item => item !== idFilter));

        }
        if (event.target.getAttribute('data-filter') === 'manufacturer') {
            const idFilter = event.target.getAttribute('data-filter-id');
            if (value)
                setManufacturerFilter([...manufacturerFilter, idFilter]);
            else
                setManufacturerFilter(manufacturerFilter.filter(item => item !== idFilter));
        }

        if (event.target.getAttribute('data-filter') === 'model') {
            const idFilter = event.target.getAttribute('data-filter-id');
            if (value)
                setModelFilter([...modelFilter, idFilter]);
            else
                setModelFilter(modelFilter.filter(item => item !== idFilter));
        }

        navigate('/equipments');
        setPage(1)
    };

    const params = { page: page, per_page: perPage, search: valueSearch, subcategory: subcategoryFilter, manufacturer: manufacturerFilter, model: modelFilter }

    useEffect(() => {
        setPage(1);
    }, [valueSearch])

    useEffect(() => {
        setData([]);
        setFilter([]);

        mutate(params, {
            onSuccess: (res) => {
                setData(res.data);
            }
        })

        mutateFilter(params, {
            onSuccess: (res) => {
                setFilter(res.data);
            }
        })
    }, [JSON.stringify(params)])

    return (
        <>
            <Header setModal={setFilterMobile} filter={true} />
            <div className="fixed w-full h-full z-30 top-0 bg-[rgba(0,0,0,0.3)]" onClick={() => setFilterMobile(false)} style={{ 'display': ` ${filterMobile ? 'block' : 'none'}` }}></div>
            <div className="w-full fixed w-0 z-40 left-0 right-0 top-0 overflow-x-hidden bg-white drop-shadow-md transition-all ease-in-out duration-500" style={{ 'width': ` ${filterMobile ? '70%' : '0%'}` }}>

                <div className="relative h-full top-0 w-full bg-white p-4 transition-all ease-in-out duration-500">
                    <div className="absolute top-[.9rem] right-[8px] text-white text-[23px]">
                        <IconButton onClick={() => setFilterMobile(false)} aria-label="Fechar filtro" size="small">
                            <CloseIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                        </IconButton>
                    </div>
                    <Filter
                        filter={filter}
                        loading={isLoadingFilter}
                        subcategoryFilter={subcategoryFilter}
                        manufacturerFilter={manufacturerFilter}
                        modelFilter={modelFilter}
                        filterChange={filterChange}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl px-8 lg:px-6 pt-5 mb-3 flex justify-center items-start gap-3 md:flex-nowrap flex-wrap">
                <div className="md:block hidden custom__scroll bg-white rounded-lg drop-shadow-md px-4 py-2 mt-2 overflow-auto max-h-[90vh] w-full md:w-1/4">
                    <Filter
                        filter={filter}
                        loading={isLoadingFilter}
                        subcategoryFilter={subcategoryFilter}
                        manufacturerFilter={manufacturerFilter}
                        modelFilter={modelFilter}
                        filterChange={filterChange}
                    />
                </div>
                <div className="md:w-5/6 w-full">
                    <div className="grid md:grid-cols-3">
                        {isLoading && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, i) => {
                            return (
                                <CardEquipmentSkeleton key={i} />
                            )
                        })
                        }
                        {data?.data?.map((item, i) => (
                            <CardEquipment data={item} key={i} type="lg" />
                        ))}

                    </div>
                    {data?.data?.length > 0 ?
                        <div className="flex justify-between md:flex-nowrap flex-wrap py-5 gap-2">
                            <span>Mostrando {data?.from}-{data?.to} de {data?.total} produtos</span>
                            <Stack spacing={3}>
                                <Pagination count={parseInt(data?.last_page) || 0} variant="outlined" onChange={pageChange} />
                            </Stack>
                        </div> : null}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Equipments;
