export const myConfig = { apiUrl: 'https://api.outlet.viamaquinas.com.br', apiKey: '2d49456d5a32da0aac402cbd7cd9b8141e6bb26d5ebc20fb8876b29aca98f700' };
export const configUsadao = {
    apiUrl: 'https://api.outlet.viamaquinas.com.br',
    apiKey: 'token-access',
    timeout: 100000
};




