export default function Button({ children, isLoading, type, onClick, className, disabled }) {
    return (
      <button
        onClick={onClick}
        type={type}
        disabled={isLoading}
        className={"disabled:opacity-80 disabled:cursor-wait w-full border h-10 rounded-full bg-yellow-400 text-gray-800 border-none uppercase text-sm font-bold hover:opacity-80 " + className.trim()}
      >
        {isLoading ? "Aguarde..." : children}
      </button>
    );
  }
  
  Button.defaultProps = {
    type: "submit",
    onClick: () => { },
    className: ""
  };
  