export default function Select({
    name,
    error,
    children,
    disabled,
    register,
    className,
  }) {
    const classes = error
      ? "border-2 border-gray-100 rounded-md h-10 block w-full px-3 focus:border-red-500 focus:outline-none placeholder:text-gray-300 focus:shadow-input-custom-error " +
        className
      : "disabled:opacity-80 disabled:bg-white border-2 border-gray-100 rounded-md h-10 block w-full px-3 focus:border-orange-500 focus:outline-none placeholder:text-gray-300 focus:shadow-input-custom " +
        className;
  
    return (
      <div className="w-full">
        <select
          name={name}
          {...(register !== undefined) ? register(name) : null}
          disabled={disabled}
          className={classes}
        >
          {children}
        </select>
        <div className="mb-4">
          {error && (
            <p className="mt-1 text-red-600 text-sm font-normal">
              {error.message}
            </p>
          )}
        </div>
      </div>
    );
  }
  
  Select.defaultProps = {
    disabled: "",
    defaultValue: "",
    className: "",
  };
  