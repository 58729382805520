import InputText from "./Form/InputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertOctagon, Download, Upload } from "lucide-react";
import registerStepTwoPJ from "../utils/schemas/register-step-two-pj";
import { useEffect, useState } from "react";
import { configUsadao, myConfig } from "../config";
import Cookies from "universal-cookie";
import FotoRosto from "../utils/img/foto-rosto-documento.png";
import Declaracao from "../utils/img/declaracao.png";
import FormButton from "./Form/Button";
import useDownloader from "react-use-downloader";
import Select from "./Form/Select";
import Option from "./Form/Option";
import { useCities, useStates, useUserInfo, useUserUpdate } from "../utils/services/queries";
import { useNavigate } from "react-router-dom";
import Button from "./Form/Button";
import { useAuth } from "../utils/providers/auth";
import InputTextMask from "./Form/InputTextMask";
import putIfNull from "../utils/lib/utils";

export default function RegisterPF(props) {
  const navigate = useNavigate();
  const { download } = useDownloader();

  const citiesMutation = useCities();
  const { user, setUser } = useAuth();
  const { data: states } = useStates();
  const { data: userInfo, isLoading } = useUserInfo();
  const { mutate: userUpdateMutate, isLoading: userUpdateLoading, data: userUpdateResponse } =
    useUserUpdate();

  const [identityValue, setIdentityValue] = useState("identity");
  const [declarationValue, setDeclarationValue] = useState("declaration");

  const validationSchema = registerStepTwoPJ;
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const sendArquives = (files) => {
    if (files) {
      files.forEach(async (file, index) => {
        const formData = new FormData();
        formData.append("files[" + index + "][title]", file.title);
        formData.append("files[" + index + "][file]", file.file[0]);

        const response = await fetch(
          configUsadao.apiUrl +
          "/cliente/documento/register",
          {
            method: "POST",
            headers: {
              contentType: false,
              mimeType: "multipart/form-data",
              "Token-Access": configUsadao.apiKey,
              authorization: "bearer " + user.token,
            },
            body: formData,
          }
        );

        response.json().then((data) => {
          console.log(data);
        });
      });
    }
  };

  const submitForm = async (data) => {
    userUpdateMutate(data);
    sendArquives([
      { title: "documento", file: data.identity },
      { title: "declaracao", file: data.declaration },
    ]);
  };


  useEffect(() => {
    setValue("gender", userInfo?.data?.gender);
    setValue("name", userInfo?.data?.name);
    setValue("cpf_cnpj", userInfo?.data?.cpf_cnpj);
    setValue("rg_ie", userInfo?.data?.rg_ie);
    setValue("ie_sintegra", userInfo?.data?.ie_sintegra);
    setValue("birth_date", putIfNull(userInfo?.data?.birth_date));
    setValue("phone_number1", putIfNull(userInfo?.data?.phone_number1));
    setValue("phone_number2", putIfNull(userInfo?.data?.phone_number2));
    setValue("email", userInfo?.data?.email);
    setValue("username", userInfo?.data?.username);
    setValue("type", userInfo?.data?.type);
    setValue("passport", userInfo?.data?.passport);
    setValue("en_cep", putIfNull(userInfo?.data?.en_cep));
    setValue("en_address", userInfo?.data?.en_address);
    setValue("en_number", userInfo?.data?.en_number);
    setValue("en_complement", userInfo?.data?.en_complement);
    setValue("en_district", userInfo?.data?.en_district);
    setValue("en_city_id", userInfo?.data?.en_city_id);
    setValue("en_state_id", userInfo?.data?.en_state_id);
  }, [userInfo?.data]);

  useEffect(() => {
    setIdentityValue(getValues("identity"));
  }, [watch("identity")]);

  useEffect(() => {
    setDeclarationValue(getValues("declaration"));
  }, [watch("declaration")]);

  useEffect(() => {
    citiesMutation.mutate(getValues("en_state_id"));
  }, [watch("en_state_id")]);

  useEffect(() => {
    if (userUpdateResponse) {
      if (userUpdateResponse.status === 200) {
        setUser(prevState => ({
          ...prevState,
          ['approved']: true
        }))
        navigate("/register-finished");
      }
    }
  }, [userUpdateResponse])

  return (
    <div className="w-full flex flex-col items-center min-h-[calc(100vh-57px)] pb-10 bg-gray-50">
      <div className="w-full max-w-[1120px] mx-auto px-5">
        <div className="text-gray-800 mt-5 md:mt-10">
          <h1 className="text-xl">
            Olá,{" "}
            <strong>{userInfo?.data?.name || ""}</strong>!
          </h1>
          <p>Complete seu cadastro para participar das nossas ofertas! 🤠</p>
        </div>
        <form
          onSubmit={handleSubmit(submitForm)}
          className="mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5"
        >
          <div className="w-full">
            <h2 className="font-medium text-gray-800 m-0 p-0 mb-3">
              1. Dados pessoais
            </h2>
            <div className="w-full rounded-lg p-5 pb-1 bg-white shadow-sm mb-4">
              <Select
                name="gender"
                error={errors.gender}
                disabled={isLoading}
                register={register}
              >
                <option value="" selected disabled>
                  Selecione o gênero
                </option>
                <Option value="F">Feminino</Option>

                <Option value="M">Masculino</Option>

                <Option value="N">Prefiro não informar</Option>
              </Select>

              <InputText
                disabled={isLoading}
                placeholder="Nome"
                name="name"
                register={register}
                error={errors.name}
              />

              <InputText
                disabled={true}
                placeholder="CPF/CNPJ"
                name="cpf_cnpj"
                register={register}
                error={errors.cpf_cnpj}
              />

              <InputText
                disabled={isLoading}
                placeholder="RG"
                name="rg_ie"
                register={register}
                error={errors.rg_ie}
              />

              <InputText
                disabled={isLoading}
                placeholder="IE/SINTEGRA"
                name="ie_sintegra"
                register={register}
                error={errors.ie_sintegra}
              />

              <InputTextMask
                disabled={isLoading}
                placeholder="Data de nascimento"
                name="birth_date"
                register={register}
                error={errors.birth_date}
                mask={"99/99/9999"}
                setValue={setValue}
              />

              <InputTextMask
                disabled={isLoading}
                placeholder="Nº de telefone"
                name="phone_number1"
                register={register}
                error={errors.phone_number1}
                mask={"(99) 99999-9999"}
                setValue={setValue}
              />

              <InputTextMask
                disabled={isLoading}
                placeholder="Nº de telefone 2"
                name="phone_number2"
                register={register}
                error={errors.phone_number1}
                mask={"(99) 99999-9999"}
                setValue={setValue}
              />
            </div>

            <h2 className="font-medium text-gray-800 mb-3">
              2. Dados para login
            </h2>

            <div className="w-full rounded-lg p-5 pb-1 bg-white shadow-sm mb-5">
              <InputText
                disabled={isLoading}
                placeholder="Nome de usuário"
                name="username"
                register={register}
                error={errors.username}
              />

              <p className="text-sm font-normal text-gray-800 mb-3">
                Neste campo deverá ser informado o <strong>apelido</strong> e/ou{" "}
                <strong>nome fantasia</strong> que irá aparecer nos lances
                efetuados por este cadastro.
              </p>

              <InputText
                disabled={true}
                placeholder="Endereço de e-mail"
                name="email"
                register={register}
                error={errors.email}
              />
            </div>
          </div>

          <div className="w-full flex flex-col">
            <h2 className="font-medium text-gray-800 mb-3">3. Endereço</h2>
            <div className="w-full rounded-lg p-5 pb-1 bg-white shadow-sm mb-4">

              <InputTextMask
                disabled={isLoading}
                placeholder="CEP"
                name="en_cep"
                register={register}
                error={errors.en_cep}
                className="w-full"
                mask={"99999-999"}
                setValue={setValue}
              />


              <div className="flex flex-row gap-3">
                <InputText
                  disabled={isLoading}
                  placeholder="Complemento"
                  name="en_complement"
                  register={register}
                  error={errors.en_complement}
                  className="mb-0"
                />

                <InputText
                  disabled={isLoading}
                  placeholder="Nº"
                  name="en_number"
                  register={register}
                  error={errors.en_number}
                  className="mb-0"
                />
              </div>

              <InputText
                disabled={isLoading}
                placeholder="Endereço"
                name="en_address"
                register={register}
                error={errors.en_address}
              />

              <Select
                name="en_state_id"
                error={errors.en_state_id}
                disabled={isLoading}
                register={register}
              >
                <option value="" selected disabled>
                  Selecione um estado
                </option>
                {states?.data.map((state) => {
                  return (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  );
                })}
              </Select>

              <Select
                name="en_city_id"
                error={errors.en_city_id}
                disabled={isLoading}
                register={register}
              >
                <option value="" selected disabled>
                  Selecione uma cidade
                </option>
                {citiesMutation?.data?.data.map((city) => {
                  return (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  );
                })}
              </Select>

              <InputText
                disabled={isLoading}
                placeholder="Bairro"
                name="en_district"
                register={register}
                error={errors.en_district}
                className="mb-0 pb-0"
              />
            </div>

            <h2 className="font-medium text-gray-800 mb-3">4. Documentação</h2>
            <div className="w-full rounded-lg p-5 bg-white shadow-sm">
              <div className="flex flex-row gap-3">
                <div className="shrink-0 w-[70px] h-[70px] flex flex-row items-center justify-center">
                  <img
                    src={FotoRosto}
                    alt="Imagem demonstrativa de uma pessoa tirando uma selfie segurando um documento com foto."
                  />
                </div>

                <div>
                  <h3 className="text-sm font-medium">
                    Tire uma foto da identidade ou cnh + rosto
                  </h3>
                  <p className="text-sm text-gray-400">
                    Foto retrato sua segurando o documento com foto ao lado do
                    seu rosto.
                  </p>
                </div>
              </div>
              <div>
                <label className="flex flex-col items-center w-full bg-gray-50 rounded-lg mt-7 py-3 px-5 cursor-pointer hover:opacity-80" htmlFor="identity">
                  <Upload className="mb-2 text-orange-500" />
                  <span className="text-sm">
                    {identityValue?.length > 0
                      ? "Arquivo: " + identityValue[0].name
                      : "Clique aqui para selecionar o arquivo"}
                  </span>
                </label>
                <input
                  hidden
                  id="identity"
                  type="file"
                  multiple={false}
                  name="identity"
                  {...register("identity")}
                />
                <span className="block text-red-400 text-sm mt-1">
                  {errors.identity?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="w-full">
            <h2 className="font-medium text-gray-800 mb-3">5. Declaração</h2>
            <div className="w-full rounded-lg p-5 bg-white shadow-sm mt-3">
              <div className="flex flex-row gap-3">
                <div className="shrink-0 w-[70px] h-[70px] flex flex-row items-center justify-center">
                  <img
                    src={Declaracao}
                    alt="Imagem demonstrativa de uma pessoa segurando uma declaração."
                  />
                </div>

                <div>
                  <h3 className="text-sm font-medium">Declaração</h3>
                  <p className="text-sm text-gray-400">
                    Baixe a declaração, assine e anexe assinada
                  </p>
                  <button
                    type="button"
                    className="mt-2 flex flex-row items-center justify-center text-sm font-medium text-orange-500"
                    onClick={() => download(
                      "https://gpvia.s3.us-east-2.amazonaws.com/.usadao_maquinas/assets/DECLARA%C3%87%C3%83O%2BCONTRATO%2BDE%2BADES%C3%83O.pdf",
                      "declaracao-contrato-de-adesao.pdf"
                    )}>
                    <Download />
                    <span className="ml-2 inline-block mt-1">Baixar declaração</span>
                  </button>
                </div>
              </div>
              <div>
                <label className="flex flex-col items-center w-full bg-gray-50 rounded-lg mt-7 py-3 px-5 cursor-pointer hover:opacity-80" htmlFor="declaration">
                  <Upload className="mb-2 text-orange-500" />
                  <span className="text-sm">
                    {declarationValue?.length > 0
                      ? "Arquivo: " + declarationValue[0].name
                      : "Clique aqui para selecionar o arquivo"}
                  </span>
                </label>
                <input
                  hidden
                  id="declaration"
                  type="file"
                  multiple={false}
                  name="declaration"
                  {...register("declaration")}
                />
                <span className="block text-red-400 text-sm mt-1">
                  {errors.declaration?.message}
                </span>
              </div>
            </div>
            <div className="mt-3"></div>
            <Button isLoading={userUpdateLoading}>
              CONCLUIR CADASTRO
            </Button>
            <button type="button" onClick={() => navigate("/perfil")} className="mt-3 h-10 w-full flex flex-row items-center justify-center text-gray-900 bg-gray-100 rounded-3xl hover:opacity-75 shrink-0 font-bold text-sm">
              PULAR ETAPA
            </button>
            <div className="flex flex-col gap-2 mt-3 w-full py-3 px-5 bg-gray-100 rounded-lg">
              <div className="flex flex-row">
                <div className="shrink-0 mr-3 text-yellow-500">
                  <AlertOctagon />
                </div>

                <p className="text-yellow-500 text-md font-medium">Atenção!</p>
              </div>
              <p className="text-sm font-normal text-gray-800">
                Ao pular esta etapa você será impossibilitado de dar lances e
                participar das ofertas, você pode concluir seu cadastro a
                qualquer momento acessando o seu perfil.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export { default as getServerSideProps } from "../utils/lib/serverProps";