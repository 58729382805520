import { Check } from "lucide-react";
import Navbar from "../components/Layout/Navbar";
import { useAuth } from "../utils/providers/auth";
import { Link } from "react-router-dom";
import header from "../assets/img/header_outlet.png";

export default function RegisterFinished() {
    const { user } = useAuth();
    const fullName = user?.name;
    const firstName = fullName?.split(" ")[0];
    return (
        <>
            <div className="max-h-[100px] lg:max-h-[80px] bg-cover py-2 font-outfit border-b-2 border-[#0076a2] border-solid" style={{ backgroundImage: "url(" + header + ")" }}>
                <div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8">
                    <Navbar />
                </div>
                <div className="w-full flex flex-col items-center justify-center min-h-[calc(100vh-57px)]">
                    <div className="flex flex-col items-center justify-center p-5 w-full max-w-[450px]">
                        <div className="bg-green-100 text-white w-28 h-28 rounded-full flex flex-row items-center justify-center mb-7 relative">
                            <div className="bg-green-500 text-white w-20 h-20 rounded-full flex flex-row items-center justify-center absolute">
                                <Check size={54} />
                            </div>
                        </div>

                        <h1 className="text-gray-800 text-lg font-medium mb-3">Cadastro concluído com sucesso!</h1>
                        <p className="text-gray-600 text-md font-normal text-center mb-7">
                            {firstName}, agora você está oficialmente habilitado a participar do nossas ofertas.
                            Aproveite para explorar nossa ampla seleção de produtos! Boa sorte.
                        </p>

                        <div className="flex flex-row items-center justify-center">
                            <Link to="/equipments" className="font-medium text-green-500">Acessar lotes</Link>
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}