import { faArrowLeft, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CardArrows(props) {
    const { customClass, onClick, type } = props;

    return (
        <div
            className={`${customClass} drop-shadow-md cursor-pointer absolute top-[20%] bg-[#006287]  px-[.75rem] py-[.55rem] rounded-[50%] z-10 text-white hover:opacity-75 transition ease-in-out duration-500`}
            onClick={onClick}
        >
            <div className="flex items-center justify-center text-[14px]">
                {(type == 'left') && <FontAwesomeIcon icon={faChevronLeft} />}
                {(type == 'right') && <FontAwesomeIcon icon={faChevronRight} />}
            </div>
        </div>
    )
}