import React, { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import header from "../assets/img/header_outlet.png";
import Footer from '../components/Layout/Footer';
import InputText from "../components/Form/InputText";
import { useSnackbar } from "notistack";
import Cookies from 'universal-cookie';
import Navbar from "../components/Layout/Navbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import loginSchema from "../utils/schemas/login.schema";
import { useLogin } from "../utils/services/queries";
import Button from "../components/Form/Button";
import jwtDecode from "jwt-decode";

const Login = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const cookies = new Cookies();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema) });

    const { mutate, isLoading, data, error } = useLogin();

    const login = (formData) => {

        mutate(formData, {
            onError: (res) => {
                enqueueSnackbar("E-mail ou senha incorretos!", { variant: 'error' });
            }
        });
    };

    if (data?.status == 200) {
        cookies.set("authorization", data.data, {
            maxAge: Number(data.data.expires_in) * 60,
        });
        if (!jwtDecode(data?.data.token).approved)
            navigate("/register-step-2");
        else
            navigate('/');
    }

    return (
        <>
            <div className="max-h-[100px] lg:max-h-[80px] bg-cover py-2 font-outfit border-b-2 border-[#0076a2] border-solid" style={{ backgroundImage: "url(" + header + ")" }}>
                <div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8">
                    <Navbar />
                </div>
                <div className="bg-gray-50 min-h-[calc(100vh-57px)] flex flex-col items-center justify-center p-5">
                    <div className="w-full max-w-sm bg-white rounded-lg shadow-sm py-4 px-6">
                        <h1 className="text-xl font-bold text-gray-700 mb-1">Bem vindo!</h1>
                        <span className="text-gray-700 block mb-5 text-sm">
                            Não tem uma conta?{" "}
                            <Link
                                to="/register"
                                className="font-medium hover:underline text-orange-500"
                            >
                                faça sua conta aqui
                            </Link>
                            .
                        </span>

                        <form onSubmit={handleSubmit(login)}>
                            <InputText
                                disabled={isLoading}
                                placeholder="Endereço de e-mail"
                                name="email"
                                register={register}
                                error={errors.email}
                            />

                            <InputText
                                disabled={isLoading}
                                placeholder="Senha de acesso"
                                name="password"
                                register={register}
                                error={errors.password}
                                type="password"
                            />

                            <Button isLoading={isLoading}>Acessar conta</Button>
                        </form>

                        <Link
                            href="/esqueci-minha-senha"
                            className="hover:underline text-gray-700 block text-center text-sm mt-4"
                        >
                            Esqueci minha senha
                        </Link>
                    </div>

                </div>

                <Footer />
            </div>

        </>
    );
}

export default Login;
