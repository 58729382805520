import CurrencyFormat from "react-currency-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faGear, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function CardEquipment(props) {
    const { data, type, isMobile } = props;
    const navigate = useNavigate();
   
    const nameSize = (type === 'lg') ? '14px' : '13px';
    const valueSize = (type === 'lg') ? '1.4rem' : '1.3rem';
    const infosSize = (type === 'lg') ? '13px' : '11px';
    if(!isMobile){
    return (
        <div className="transition-all ease-in-out duration-500 hover:mt-[-7px] min-w-[300px]" onClick={() => navigate('/equipment/' + data.id)}>
            <div className="mr-2 ml-1 sm:max-h-[310px] sm:min-h-[420px] drop-shadow-md bg-white rounded-3xl my-2 cursor-pointer hover:drop-shadow-lg transition-all ease-in-out duration-500">
                <div className="rounded-t-3xl min-h-[250px] sm:min-h-[250px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url("${data?.product?.main_picture?.path}")` }}></div>

                <div className="p-3">
                    <div className="uppercase leading-4 text-[#3a3a3a] font-bold pb-2 min-h-[40px]" style={{ fontSize: nameSize }}>{data?.product?.name}</div>
                    <div className="grid grid-cols-2 gap-y-1 py-2 text-[#818181] opacity-60 pt-3 pl-10" style={{ fontSize: infosSize }}>
                        <div className="w-full flex items-center gap-1">
                            <FontAwesomeIcon icon={faCalendar} />
                            <div>{data?.product?.year}</div>
                        </div>
                        <div className="w-full flex items-center gap-1">
                            <FontAwesomeIcon icon={faLocationDot} />
                            <div>{data?.state?.uf}</div>
                        </div>
                    </div>
                    {/*<div className="uppercase leading-4 text-[#10b366] font-semibold" style={{ fontSize: valueSize }}><CurrencyFormat value={parseFloat(data?.price)} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div className="value">{value}</div>} /></div>*/}
                    <button className='py-3 mt-3 px-6 text-black rounded uppercase text-xs font-semibold bg-[#f5ac29] shadow-lg hover:opacity-75 w-full'>
                                        Solicitar orçamento
                                    </button>
                    
                </div>
            </div>
        </div>
    )}else{
        <div className="transition-all ease-in-out duration-500 hover:mt-[-7px] min-w-[250px]" onClick={() => navigate('/equipment/' + data.id)}>
            <div className="mr-2 ml-1 sm:max-h-[310px] sm:min-h-[310px] drop-shadow-md bg-white rounded-lg my-2 cursor-pointer hover:drop-shadow-lg transition-all ease-in-out duration-500">
                <div className="rounded-t-lg min-h-[250px] sm:min-h-[150px] bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url("${data?.product?.main_picture?.path}")` }}></div>

                <div className="p-3">
                    <div className="uppercase leading-4 text-[#3a3a3a] font-bold pb-2 min-h-[40px]" style={{ fontSize: nameSize }}>{data?.product?.name}</div>
                    <div className="grid grid-cols-2 gap-y-1 py-2 text-[#818181] opacity-60 pt-1 pl-10" style={{ fontSize: infosSize }}>
                        <div className="w-full flex items-center gap-1">
                            <FontAwesomeIcon icon={faCalendar} />
                            <div>{data?.product?.year}</div>
                        </div>
                        <div className="w-full flex items-center gap-1">
                            <FontAwesomeIcon icon={faLocationDot} />
                            <div>{data?.state?.uf}</div>
                        </div>
                    </div>
                    {/*<div className="uppercase leading-4 text-[#10b366] font-semibold" style={{ fontSize: valueSize }}><CurrencyFormat value={parseFloat(data?.price)} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div className="value">{value}</div>} /></div>*/}
                    <button className='py-3 mt-3 px-6 text-black rounded uppercase text-xs font-semibold bg-[#f5ac29] shadow-lg hover:opacity-75 w-full'>
                                        Solicitar orçamento
                                    </button>
                    
                </div>
            </div>
        </div>
    }
}