import Skeleton from '@mui/material/Skeleton';

export default function CardEquipmentSkeleton(props) {
    const { isMobile } = props
    if(!isMobile){
    return (
        <div className="col-md-12 card-home">
            <div className="mr-2 ml-1 sm:max-h-[310px] sm:min-h-[310px] drop-shadow-md bg-white rounded-lg my-2 cursor-pointer hover:drop-shadow-lg transition-all ease-in-out duration-500">
                <Skeleton animation="wave" variant="rectangular" className="rounded-t" height={160} />
                <div className="p-3">
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '14px' }} />
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} width={150} />
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1 py-2 text-[#818181] opacity-40 pt-3">
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                    </div>
                </div>
            </div>
        </div>
    )}else{
        return (
            <div className="col-md-12 card-home">
            <div className="mr-2 ml-1 sm:max-h-[310px] sm:min-h-[310px] drop-shadow-md bg-white rounded-lg my-2 cursor-pointer hover:drop-shadow-lg transition-all ease-in-out duration-500">
                <Skeleton animation="wave" variant="rectangular" className="rounded-t" height={160} />
                <div className="p-3">
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '14px' }} />
                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} width={150} />
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1 py-2 text-[#818181] opacity-40 pt-3">
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '11px' }} width={40} />
                    </div>
                </div>
            </div>
        </div>
        )
    }
}