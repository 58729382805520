import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CardEquipment from "../components/Home/CardEquipment";
import CardArrows from "../components/Home/CardArrows";
import CardNews from "../components/Home/CardNews";
import Footer from '../components/Layout/Footer';
import CardEquipmentSkeleton from "../components/Home/CardEquipmentSkeleton";
import Navbar from "../components/Layout/Navbar";
import { useProducts, useVisiteds } from "../utils/services/queries";
import headerVideo from "../assets/img/VÍDEOSITE.mp4";
import headerVideoMob from "../assets/img/videomobile.mp4";
import {isMobile} from "react-device-detect";
import capa from "../assets/img/IMG_2815.JPG"

const Home = (props) => {
    const navigate = useNavigate();
    const [cookies] = useCookies([]);
    const { mutate, isLoading } = useProducts();
    const [searchParams, setSearchParams] = useSearchParams();
    const containerRef = useRef(null);
    const item = {
        "id": 1,
        "visits": 2,
        "price": "198000.00",
        "create_time": "2023-12-07T12:42:58.000000Z",
        "product": {
            "sector_id": 2,
            "category_id": 4,
            "manufacturer_id": 28,
            "model_id": 3903,
            "custom_id": null,
            "name": "PÁ CARREGADEIRA JOHN DEERE 444G ANO 2022",
            "year": 2022,
            "horse_power": "",
            "engine_hours": null,
            "preservation": "Regular",
            "description": "<p>P&aacute; Carregadeira Cat 924k<br />\r\nAno 2011<br />\r\n&nbsp;</p>",
            "main_picture": {
                "id": 390508,
                "path": capa
            }
        },
        "state": {
            "id": 13,
            "uf": "GO"
        },
        "city": {
            "id": 1409,
            "name": "Lucas do Rio Verde"
        }
    }

    const [data, setData] = useState([]);
    const [news, setNews] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(["Pás Carregadeiras", 4]);
    const videoRef = useRef(null);

    const categories = [
        ["Pás Carregadeiras", 4, "https://media.discordapp.net/attachments/1011312496839249962/1252718001380397200/Agrupar_6_copiar_2.png?ex=66733c0d&is=6671ea8d&hm=8ae5794f05fc59d5a37ff1fb05db928d63345c0d7f73c545b00cd1f2fb3fbac4&"]];

    // Responsive slider settings
    const [sliderSettings, setSliderSettings] = useState({
        centerMode: false,
        infinite: false,
        centerPadding: '0px',
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <CardArrows customClass="right-[-12px]" type="right" />,
        prevArrow: <CardArrows customClass="left-[-12px]" type="left" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    infinite: false,
                    centerPadding: '0px',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0.45rem',
                    slidesToShow: 1
                }
            }]
    });

    // Function to update search parameters and fetch data
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSearchParams({ subcategory: selectedCategory[1] });
    };

    useEffect(() => {

        const params = {
            page: 1,
            per_page: 10,
            order: "visited",
            order_type: "desc",
            subcategory: selectedCategory[1] ? [selectedCategory[1]] : null
        };
        mutate(params, {
            onSuccess: (res) => {
                setData(res.data);
            }
        })
    }, [searchParams, cookies]);

    useEffect(() => {
        // Update slider settings based on screen size
        const handleResize = () => {
            const isMobile = window.innerWidth <= 600; // Adjust breakpoint as needed
            setSliderSettings({
                ...sliderSettings,
                centerMode: isMobile,
                slidesToShow: isMobile ? 1 : 5, 
                centerPadding: isMobile ? '0.45rem' : '0px' 
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call on initial render

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    if (!isMobile) {
    return (
        <>
            <div className="relative font-outfit border-b-2 border-[#0076a2] border-solid bg-[#3a3a3a] flex flex-col items-center">

                <video
                    ref={videoRef}
                    className="w-full h-[40vh] object-cover"
                    autoPlay
                    loop
                    muted
                    style={{ objectFit: 'cover' }}
                >
                    <source src={headerVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <div className="top-0 left-0 w-full h-full bg-opacity-100" />
                <div className="w-full text-[#f2f2f2] text-[2.5rem] text-center font-outfit leading-8 absolute top-[23vh]  w-[95vh] min-[1500px]:w-[80vh] left-1/2 -translate-x-1/2 -translate-y-1/2">Alugue equipamentos <span className="text-[#f5ac29] font-bold">pesados</span> com facilidade, agilidade e total segurança.</div>

                <div className="flex flex-row px-5 justify-center w-full bg-[#353535] border-t-[#f5ac29] border-t-4">
                    {categories.map((category, index) => (
                        <div
                            key={index}
                            className={`px-6 no-underline  hover:mt-[-1px] hover:cursor-pointer hover:bg-[#292929] py-6 transition ease-in-out duration-500 font-bold ${selectedCategory[1] === category[1] ? 'bg-[#f5ac29] text-[#292929] hover:bg-[#f5ac29]' : 'text-[#f5ac29]'}`}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {category[0]}
                        </div>
                    ))}
                </div>
                <Navbar />
                {/*<span className="mt-4 text-white text-[2rem] font-bold font-outfit">{selectedCategory[0]}</span>*/}
                <div
                    className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8 py-4 mt-8 overflow-x-auto"
                    ref={containerRef}
                    style={{ scrollbarColor: '#f5ac29 transparent',borderRadius: '8px' }}
                >
                    <div className="flex gap-2"> {/* Create a flex container for horizontal scrolling */}
                                    <div key={1} className="p-2">
                                        <CardEquipment data={item} key={1} type="lg" />
                                    </div>
                    </div>
                </div>

                {news.length > 0 ?
                    <div className="bg-gradient-to-b from-[#0086b9] to-[#006287] mt-8">
                        <div className="mx-auto max-w-6xl px-4 lg:px-6 lg:px-8 py-4">
                            <div className="font-semibold text-[1.3rem] text-white">Notícias sobre o Agronegócio</div>
                            <div className="grid lg:grid-cols-3 gap-6 lg:gap-4 py-2">
                                {news.map((item, i) => {
                                    return (
                                        <CardNews data={item} key={i} />
                                    )
                                })}
                            </div>
                        </div>
                    </div> : null}

                <div className="py-4 flex justify-center items-center mt-3">
                </div>
                <Footer />
            </div>

        </>
    );}
    else {
        return (
            <>
            <div className="relative font-outfit border-b-2 border-[#0076a2] border-solid bg-[#3a3a3a] flex flex-col items-center">

                <video
                    playsInline
                    ref={videoRef}
                    className="w-full h-[40vh] object-contain"
                    autoPlay={true} 
                    loop={true}
                    controls={false} 
                    muted
                    style={{ objectFit: 'cover' }}
                >
                    Your browser does not support the video tag.
                    <source src={headerVideoMob} type="video/mp4" />
                    
                </video>

                <div className="top-0 left-0 w-full h-full bg-opacity-100" />
                <div className="w-full text-[#f2f2f2] text-[1.5rem] text-center font-outfit leading-8 absolute top-[23vh]  w-[95vh] left-1/2 -translate-x-1/2 -translate-y-1/2">Alugue equipamentos <span className="text-[#f5ac29] font-bold">pesados</span> com facilidade, agilidade e total segurança.</div>
                
                <div className="flex flex-row w-full border-t-[#f5ac29] border-t-4 overflow-x-auto">
                <span className="absolute left-0 my-6 ml-4 text-[#f5ac29] text-[0.9rem]">CATEGORIAS</span>
                    {categories.map((category, index) => (
                        <div
                            key={index}
                            className={`bg-cover bg-center bg-no-repeat min-w-[238px] mt-16 ml-4 text-[#f5ac29] hover:cursor-pointer min-h-[203px] transition ease-in-out duration-500 font-bold `}
                            onClick={() => handleCategoryClick(category)}
                            style={{ backgroundImage: `url("${category[2]}")` }}
                        >
                            <span className="ml-4 pb-4 justify-start items-end font-normal flex h-full text-gray-300 text-[1rem]">{category[0]}</span>
                        </div>
                    ))}
                </div>
                <Navbar isMobile={isMobile}/>
                {/*<span className="mt-4 text-white text-[2rem] font-bold font-outfit">{selectedCategory[0]}</span>*/}
                
                <div
                    className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8 py-4 overflow-x-auto w-full"
                    ref={containerRef}
                    style={{ scrollbarColor: '#f5ac29 transparent',borderRadius: '8px' }}
                >
                    <span className="absolute left-0 mt-6 ml-4 text-[#f5ac29] text-[0.9rem]">ALUGUE AGORA</span>
                    {<div className="flex gap-1 mt-14 "> 
                        {(!isLoading) ?
                            data?.data?.map((item, i) => { // Show only the first 4 items
                                return (
                                    <div key={i} className="p-2">
                                        <CardEquipment data={item} key={i} type="lg" />
                                    </div>
                                )
                            })
                            : [0, 1, 2, 3].map((item, i) => { // Show 4 skeleton loaders
                                return (
                                    <CardEquipmentSkeleton key={i} />
                                )
                            })}
                    </div>}
                </div>

                {news.length > 0 ?
                    <div className="bg-gradient-to-b from-[#0086b9] to-[#006287] mt-8">
                        <div className="mx-auto max-w-6xl px-4 lg:px-6 lg:px-8 py-4">
                            <div className="font-semibold text-[1.3rem] text-white">Notícias sobre o Agronegócio</div>
                            <div className="grid lg:grid-cols-3 gap-6 lg:gap-4 py-2">
                                {news.map((item, i) => {
                                    return (
                                        <CardNews data={item} key={i} />
                                    )
                                })}
                            </div>
                        </div>
                    </div> : null}

                <div className="py-4 flex justify-center items-center mt-3">
                </div>
                <Footer />
            </div>

        </>
        )
}}

export default Home;