import * as yup from "yup";

const registerSchema = yup.object().shape({
    type: yup.string().required("Selecione um tipo de usuário"),
    cpf_cnpj: yup.string().required("Insira um cpf ou cnpj."),
    name: yup.string().required("Digite um nome."),
    phone_number1: yup.string().required("Forneça um telefone para contato."),
    email: yup.string().email("Preencha um e-mail válido.").required("O campo e-mail precisa ser preenchido."),
    password: yup.string().required("A senha é necessária."),
    accept: yup.boolean("É preciso aceitar os termos.").oneOf([true], "É preciso aceitar os termos.").required("É preciso aceitar os termos.")
}).required();

export default registerSchema;