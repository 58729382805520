import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Link from "@mui/material/Link";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Privacy = (props) => {
    return (
        <>
            <Header filter={false} />
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-4">
                <div className='text-center text-[2.5rem] leading-[2.3rem]'>Política de Privacidade</div>
                <div className='text-center text-[12px]'>Última atualização em 07 de novembro 2022</div>
                <div className="py-6">
                    Esta <b>Política de Privacidade</b> aplica-se às informações coletadas, usadas e de qualquer modo tratadas quando o Usuário acessa e utiliza alguma das plataformas digitais pertencentes ao <b>GRUPO VIA MÁQUINAS</b>, a fim de garantir que seja proporcionada a você uma melhor experiência de navegação nos nossos sites.
                    <br></br><br></br>
                    São empresas e sites pertencentes ao <b>GRUPO VIA MÁQUINAS</b>:
                    <br></br><br></br>
                    Grupo Via Máquinas - <Link href="http://grupoviamaquinas.com.br/" target="_blank">http://grupoviamaquinas.com.br/</Link><br></br>
                    Via Consulti - <Link href="https://viaconsulti.com.br/" target="_blank">https://viaconsulti.com.br/</Link><br></br>
                    Leilão Usadão Máquinas - <Link href="https://usadaomaquinas.com.br/" target="_blank">https://usadaomaquinas.com.br/</Link><br></br>
                    Via Conseguros - <Link href="https://conseguros.com.br/" target="_blank">https://conseguros.com.br/</Link><br></br>
                    Via SGD - <Link href="https://viasgd.com.br/" target="_blank">https://viasgd.com.br/</Link><br></br>
                    Via Máquinas - <Link href="https://viamaquinas.com.br/" target="_blank">https://viamaquinas.com.br/</Link><br></br>
                    Via pelo Futuro - <Link href="https://viapelofuturo.com.br/" target="_blank">https://viapelofuturo.com.br/</Link>
                    <br></br><br></br>
                    Nós, do <b>GRUPO VIA MÁQUINAS</b>, estamos comprometidos em preservar os seus dados pessoais e protegê-los. Dessa forma, o objetivo desta Política de Privacidade é esclarecer quais informações são coletadas dos usuários de nossos sites e de que forma esses dados são utilizados, nos termos do que estabelece a Lei Geral de Proteção de Dados Pessoais nº 13.709/2018 ("LGPD").
                    <br></br><br></br>

                    Para facilitar a sua compreensão, dispomos abaixo alguns conceitos importantes:
                    <br></br><br></br>
                    <b>Dado Pessoal:</b> toda e qualquer informação relacionada à pessoa natural identificada ou identificável, como nome, RG, CPF e endereço, endereço IP com geolocalização e dados financeiros e de consumo<br></br>
                    <b>Controlador dos Dados Pessoais:</b> Pessoa física ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.<br></br>
                    <b>Operador dos Dados Pessoais:</b> Pessoa física ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.<br></br>
                    <b>Tratamento de Dados Pessoais:</b> Toda operação realizada com dados pessoais, como a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle de informação, modificação, comunicação, transferência, difusão ou extração.<br></br>
                    <b>Titular dos Dados Pessoais:</b> Pessoa física a quem se referem os dados pessoais que são objeto do tratamento.<br></br>
                    <b>Consentimento:</b> Manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada.<br></br>
                    <b>LGPD:</b> Lei Geral de Proteção de Dados - lei nº13.709/2018
                    <br></br><br></br>

                    1. O <b>GRUPO VIA MÁQUINAS</b> poderá coletar seus dados pessoais nas seguintes hipóteses:
                    <br></br><br></br>

                    a. por meio de cookies, quando você navega nos nossos sites, conforme explicado em nossa Política de Cookies (https://grupoviamaquinas.com.br/cookies);<br></br>
                    b. quando você realiza o seu cadastro nos nossos sites (nome, CPF, RG, e-mail, CNPJ, endereço completo, número de celular, data de nascimento);<br></br>
                    c. com o seu consentimento, para receber publicidade personalizada;<br></br>
                    d. por meio de nossos canais de atendimento;<br></br>
                    f. quando você realiza login, por meio da coleta do seu endereço IP.<br></br>
                    <br></br><br></br>

                    1. Vale destacar que os nossos sites poderão não funcionar corretamente se todos os cookies forem desativados, conforme explicado na nossa Política de Cookies. O mesmo acontece se não pudermos coletar os seus dados pessoais, visto que não será possível realizar o cadastro e utilizar os nossos serviços. Por esta razão é tão importante que você forneça o seu consentimento para que possamos coletar os seus dados e incluí-los nos nossos registros. De nossa parte, nos preocupamos com a sua tranquilidade e, por isso, nos comprometemos a realizar o tratamento dos seus dados com o máximo de segurança e sigilo.
                    <br></br><br></br>

                    1. Lembrando que, se você excluir ou não aceitar a navegação por cookies, quando você visitar nossos sites outra vez, será perguntado a você novamente a respeito do seu consentimento.
                    <br></br><br></br>

                    1. Considerando as atividades desempenhadas pelo <b>GRUPO VIA MÁQUINAS</b>, a legislação permite que a coleta de dados pessoais poderá ser feita somente com o consentimento do titular (art. 7º, inciso IX da LGPD), ou se dispor de uma finalidade específica com base no legítimo interesse (art. 7º, inciso IX da LGPD), bem como para cumprimento de um dever legal ou regulatório (art. 7º, inciso II da LGPD) e para execução de um contrato ou procedimentos preliminares  relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados (art. 7º, inciso V da LGPD).
                    <br></br><br></br>

                    1. No nosso caso, realizaremos o tratamento dos seus dados pessoais com os seguintes objetivos com base no legítimo interesse nas seguintes hipóteses:
                    <br></br><br></br>

                    a. Se você estiver cadastrado em nossos sites, precisaremos validar e autenticar a sua identidade a cada acesso;<br></br>
                    b. Para aprimorar os nossos serviços e personalizá-los;<br></br>
                    c. Comunicar novas promoções e informar a respeito de conteúdos de relevância;<br></br>
                    d. Para cumprimento de obrigações judiciais, incluindo a defesa dos interesses do <b>GRUPO VIA MÁQUINAS</b>, se houver necessidade;<br></br>
                    e. Permitir que os usuários dos nossos sites possam reivindicar seus direitos;<br></br>
                    f. Para realizar auditorias, avaliações de risco e demais procedimentos internos de Compliance.
                    <br></br><br></br>

                    1. Para fins de cumprimento de deveres legais e regulatórios o GRUPO VIA MÁQUINAS poderá armazenar e/ou transmitir dados pessoais ao órgão ou autoridade que a legislação aplicável determinar, em especial:
                    <br></br><br></br>

                    a. Para prestar informações e/ou fornecer documentos às Juntas Comerciais e outros órgãos regulatórios ou administrativos;<br></br>
                    b. Para permitir registros de crédito no Sistema de Informações de Crédito do Banco Central - (SCR);<br></br>
                    c. Para cumprir as obrigações impostas nos regimes tributários federal, estadual e municipal;<br></br>
                    d. Para cumprir ordens expedidas por órgãos administrativos do Poder Público, tais como Receita Federal, Secretarias da Fazenda e Detran.<br></br>
                    e. Para cumprir normas de Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo.
                    <br></br><br></br>

                    1. Considerando o fornecimento do seu consentimento prévio, o <b>GRUPO VIA MÁQUINAS</b> poderá, ainda:
                    <br></br><br></br>

                    a. Enviar e-mails e mensagens via SMS ou Whatsapp para informar sobre leilões, novos serviços ou sobre novidades do GRUPO VIA MÁQUINAS. Esta finalidade é aplicável quando você nos forneceu nome, e-mail e número de telefone celular, mas ainda não realizou seu cadastro completo nos sites;<br></br>
                    b. Enviar publicidade personalizada a você através das redes sociais, quando você ainda não tenha realizado o cadastro nos nossos sites;
                    <br></br><br></br>

                    1. Lembrando que, para os fins do item anterior, o seu consentimento poderá ser retirado, a qualquer momento, por meio do próprio e-mail que você recebeu ou através de e-mail informando a revogação do consentimento enviado para <Link href="mailto:rubens@viamaquinas.com.br">rubens@viamaquinas.com.br</Link>.
                    <br></br><br></br>

                    1. Além de coletar e realizar o tratamento de seus dados, poderemos compartilhá-los, para as finalidades expostas nos itens anteriores. Entretanto, o GRUPO VIA MÁQUINAS se compromete com a segurança do compartilhamento especificamente na hipótese de fornecimento de informações para órgãos ou entidades públicas e privadas em decorrência de obrigações legais. Além disso, o GRUPO VIA MÁQUINAS também assegura a privacidade e segurança dos seus dados no compartilhamento com empresas parceiras que auxiliam na viabilização dos serviços contratados por você.
                    <br></br><br></br>

                    1. O armazenamento dos seus dados será feito mediante a utilização dos servidores cloud, os quais foram minuciosamente escolhidos pelo GRUPO VIA MÁQUINAS através de critérios técnicos e a utilização de tecnologia avançada e segura.
                    <br></br><br></br>

                    1. Os seus dados serão armazenados pelo tempo necessário para cumprir com as finalidades dispostas nos itens 5, 6 e 7, considerando as legislações aplicáveis.
                    <br></br><br></br>

                    1. O GRUPO VIA MÁQUINAS está comprometido em adotar as melhores práticas e a tomar todas as medidas razoáveis para proteger seus dados pessoais contra acesso não autorizado, alteração acidental ou ilícita, divulgação não autorizada ou destruição de seus dados pessoais.
                    <br></br><br></br>

                    1. Para garantir a sua privacidade e a proteção dos seus dados pessoais, adotamos recursos tecnológicos avançados para garantir a segurança de todos os dados tratados pelo GRUPO VIA MÁQUINAS. Entre as medidas de segurança implementadas estão a implementação de controles de acesso a sistemas e ambientes de tratamento de dados, técnicas de criptografia e a instalação de barreiras contra o acesso indevido às bases de dados (incluindo firewalls), entre outros controles de segurança da informação.
                    <br></br><br></br>

                    1. Também, para cada usuário da plataforma Leilão Usadão Máquinas é criada uma sequência alfanumérica, vinculada exclusivamente à sua conta, destinada à identificação dos lances e arrematações realizados nos leilões, garantindo que os lances realizados não sejam vinculados ao usuário por terceiros.
                    <br></br><br></br>

                    1. O GRUPO VIA MÁQUINAS se esforça para proteger a privacidade de seus dados pessoais, porém não podemos garantir total segurança. Usos não autorizados de seus dados pessoais por terceiros, falhas de hardware ou software que não estejam sob controle do GRUPO VIA MÁQUINAS e outros fatores externos podem comprometer a segurança dos seus dados pessoais. Por isso, sua atuação é fundamental para a manutenção de um ambiente seguro. Caso você identifique ou tome conhecimento de qualquer fator que comprometa a segurança dos seus dados na sua relação com o GRUPO VIA MÁQUINAS, pedimos a gentileza de entrar em contato conosco por meio do e-mail: (<Link href="mailto:rubens@viamaquinas.com.br">rubens@viamaquinas.com.br</Link>).
                    <br></br><br></br>

                    1. Como titular de dados pessoais, você poderá exercer todos os direitos previstos nos art. 17 e seguintes da LGPD, mediante solicitação enviada para o e-mail (<Link href="mailto:rubens@viamaquinas.com.br">rubens@viamaquinas.com.br</Link>), como por exemplo:
                    <br></br><br></br>

                    a. Confirmação da existência de tratamento: permite que você possa requisitar e receber uma confirmação sobre a existência do tratamento de seus dados pessoais;<br></br>
                    b. Acesso aos dados pessoais: permite que você requisite e receba uma cópia dos dados pessoais que porventura sejam tratados;<br></br>
                    c. Correção de dados incompletos, inexatos ou desatualizados: caso você identifique alguma incorreção ou imprecisão nos seus dados pessoais, poderá entrar em contrato com a nossa Central de Atendimento para corrigi-los;<br></br>
                    d. Anonimização, bloqueio ou eliminação dos dados pessoais desnecessários, excessivos ou tratados em desconformidade com a legislação: permite que seja requerida a anonimização, o bloqueio ou a eliminação dos dados pessoais da nossa base. Todos os dados pessoais coletados serão anonimizados, bloqueados ou eliminados dos nossos servidores caso seja possível e requisitado por você.<br></br>
                    e. Portabilidade: obter acesso aos dados pessoais que são tratados em formato estruturado e interoperável;<br></br>
                    f. Revogar ou não fornecer o consentimento: Você tem o direito de revogar o seu consentimento ou, se for o caso, de não nos fornecê-lo e ser informado sobre as consequências da negativa. Em alguns casos, é possível que da negativa decorra a impossibilidade de lhe prover determinados produtos ou serviços;<br></br>
                    g. Revisão de decisões automatizadas: Você tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado dos dados pessoais e de receber informações claras e adequadas a respeito dos critérios utilizados na decisão, desde que essas informações não violem qualquer segredo comercial do <b>GRUPO VIA MÁQUINAS</b>;<br></br>
                    h. Oposição ao tratamento: Você também tem o direito de se opor a determinadas finalidades de tratamento de dados pessoais, como, por exemplo, o envio de publicidade, newsletter ou novas ofertas, e<br></br>
                    i. Eliminação dos dados pessoais tratados com consentimento: caso você tenha dado o seu consentimento para uma finalidade de tratamento, você pode requisitar a eliminação dos dados pessoais que armazenamos até então.<br></br>
                    <br></br>

                    1. Nos termos dispostos nesta Política de Privacidade, o <b>GRUPO VIA MÁQUINAS</b> poderá solicitar informações específicas para confirmar sua identidade e garantir a possibilidade de que você possa exercer os seus Direitos. Esta é uma medida de segurança para assegurar que os seus dados não sejam divulgados a qualquer pessoa que não tenha legitimidade para recebê-los.
                    <br></br><br></br>

                    1. Os seus direitos como titular de dados pessoais não são ilimitados e, por isso, apresentamos abaixo algumas situações que podem levar à negativa total ou parcial deles:
                    <br></br><br></br>

                    a. Preservação de segredo de negócio e propriedade intelectual;<br></br>
                    b. Violação de direitos e liberdades de terceiros;<br></br>
                    c. As informações estão anonimizadas e, portanto, não são dados pessoais;<br></br>
                    d. Obstrução à lei e justiça;<br></br>
                    d. Os interesses legítimos que se sobrepõem aos do titular dos dados pessoais;<br></br>
                    e. Se o titular fez requisições repetitivas, reiteradas ou excessivas,<br></br>
                    f. Se o pedido não pode ser atendido, pois contraria Leis ou regulamentos aplicáveis.
                    <br></br><br></br>

                    1. Poderemos alterar esta Política de Privacidade a qualquer tempo, cabendo ao Usuário verificá-la sempre que efetuar o acesso às nossas plataformas. Caso as alterações realizadas sejam significativas e relevantes para a finalidade e o interesse dos Usuários, os notificaremos por meio dos contatos fornecidos.<br></br>
                    2. Caso seja necessário realizar alguma solicitação relativa a este Aviso de Privacidade ou caso você tenha alguma pergunta, comentário ou reclamação sobre como lidamos com seus dados, você poderá entrar em contato com o Encarregado pela Proteção de Dados Pessoais (DPO), através do e-mail: <Link href="mailto:rubens@viamaquinas.com.br">rubens@viamaquinas.com.br</Link>.
                    <br></br><br></br>

                    Esta Política de Privacidade foi atualizada pela última vez em 07 de novembro de 2022.
                </div>

            </div>
            <Footer />
        </>
    )
}

function createData(
    domain: string,
    name: string,
    timelife: number,
    description: string,
    type: string,
) {
    return { domain, name, timelife, description, type };
}


export const Cookies = (props) => {

    const rows = [
        createData('.grupoviamaquinas.com.br', '', '', 'Autenticação', 'Funcional'),
        createData('.viaconsulti.com.br', '', '', 'Cookie para track de navegação e análise da experiência do usuário em nossa plataforma utilizando IDs anônimos e diferenciando usuário', 'Analítico'),
        createData('.usadaomaquinas.com.br', '', '', 'Autenticação', 'Funcional'),
        createData('.conseguros.com.br', '', '', 'Autenticação', 'Funcional'),
    ];
    return (
        <>
            <Header filter={false} />
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-4">
                <div className='text-center text-[2.5rem] leading-[2.3rem]'>Política de Cookies</div>
                <div className='text-center text-[12px]'>Última atualização em 07 de novembro 2022</div>
                <div className="py-6">
                    Para garantir que seja proporcionada a você uma melhor experiência de navegação nos sites das empresas pertencentes ao GRUPO VIA MÁQUINAS são utilizados cookies, necessários para fornecer funcionalidades essenciais, como sessões de login, e não podem ser desativados. Outros cookies são utilizados melhorar o desempenho de nossos websites e a sua experiência através de conteúdo personalizado, funcionalidades nas redes sociais e análise de tráfego.
                    <br></br><br></br>
                    São empresas e sites pertencentes ao GRUPO VIA MÁQUINAS:
                    <br></br><br></br>
                    Grupo Via Máquinas - <Link href="http://grupoviamaquinas.com.br/" target="_blank">http://grupoviamaquinas.com.br/</Link><br></br>
                    Via Consulti - <Link href="https://viaconsulti.com.br/" target="_blank">https://viaconsulti.com.br/</Link><br></br>
                    Leilão Usadão Máquinas - <Link href="https://usadaomaquinas.com.br/" target="_blank">https://usadaomaquinas.com.br/</Link><br></br>
                    Via Conseguros - <Link href="https://conseguros.com.br/" target="_blank">https://conseguros.com.br/</Link><br></br>
                    Via SGD - <Link href="https://viasgd.com.br/" target="_blank">https://viasgd.com.br/</Link><br></br>
                    Via pelo Futuro - <Link href="https://viapelofuturo.com.br/" target="_blank">https://viapelofuturo.com.br/</Link>
                    <br></br><br></br>
                    1. O que é um cookie?
                    <br></br><br></br>
                    Um cookie é um pequeno e simples arquivo de texto que é enviado junto com páginas de sites e armazenado pelo seu navegador em seu computador ou outro dispositivo. As informações armazenadas podem ser enviadas de volta aos nossos servidores. Isto facilita a personalização da página acessada de acordo com o seu perfil ou, ainda, facilitar o transporte de dados entre as páginas de um mesmo site, otimizando a sua navegação.
                    <br></br><br></br>
                    2. Tipos de Cookies que utilizamos
                    <br></br><br></br>
                    2.1 Cookies funcionais
                    <br></br><br></br>
                    Alguns cookies garantem que certas partes do site funcionem corretamente e que suas preferências de usuário permaneçam conhecidas. Ao inserir cookies funcionais, a visita ao site é facilitada, como, por exemplo, após estar logado, você pode transitar por nossas páginas, sem que tenha que fazer um novo login a cada link acessado. Da mesma forma, um item inserido no carrinho de compras permanecerá lá. Dessa forma, o usuário não precisa inserir repetidamente as mesmas informações ao visitar nosso site.
                    <br></br><br></br>
                    2.2 Cookies analíticos
                    <br></br><br></br>
                    Cookies analíticos servem para analisar padrões de comportamento do visitante, permitindo assim a melhora da experiência de utilização do site, além de nos permitir identificar áreas do website que precisam de manutenção. Esta informação é anônima (não pode ser usada identificá-lo e não contêm informação pessoal como nome e endereço de e-mail) e é apenas utilizada para fins estatísticos.
                    <br></br><br></br>
                    2.3 Botões de mídia social
                    <br></br><br></br>
                    Em nosso site, incluímos botões para Whatsapp, visando facilitar a comunicação entre você e nossos colaboradores. Este código insere cookies.
                    <br></br><br></br>
                    3. Cookies inseridos
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Domínio</TableCell>
                                    <TableCell align="right">Nome</TableCell>
                                    <TableCell align="right">Tempo de vida</TableCell>
                                    <TableCell align="right">Descrição (Finalidade)</TableCell>
                                    <TableCell align="right">Tipo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.domain}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.domain}
                                        </TableCell>
                                        <TableCell align="right">{row.name}</TableCell>
                                        <TableCell align="right">{row.timelife}</TableCell>
                                        <TableCell align="right">{row.description}</TableCell>
                                        <TableCell align="right">{row.type}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br>
                    4. Configurações e Preferências de Cookies
                    <br></br><br></br>
                    Em geral, os navegadores são configurados para automaticamente permitir o uso de cookies nos seus dispositivos. Porém, os seguintes mecanismos estão disponíveis para que você realize a configuração e selecione suas preferências de cookies:
                    <br></br><br></br>
                    <b>Preferências de Cookies</b> - Ao acessar o nosso site, será apresentado o banner de gestão de preferências de cookies, onde podem ser configuradas as categorias de cookies permitidas ou pode-se aceitar o uso de todos os cookies.
                    <br></br>
                    <b>Excluir Cookies</b> - Para realizar a exclusão dos cookies coletados em seu dispositivo, você deve acessar a seção de configurações/preferências de seu navegador e buscar pela opção de excluir dados de navegação e cookies.
                    <br></br>
                    <b>Bloquear ou Permitir Cookies</b> - Se não desejar que os sites armazenem cookies em seu computador, você pode bloquear os cookies. Informamos que isso pode impedir que algumas páginas sejam exibidas corretamente. Esta configuração também deve ser realizada em seu navegador, acessando a seção de configurações/preferências de privacidade, onde você pode fazer a configuração se deseja aceitar, bloquear ou receber solicitações sobre cookies primários e de terceiros.
                    <br></br><br></br>
                    Sugerimos que consulte as instruções de seu navegador para realização das configurações citadas acima.
                    <br></br><br></br>
                    5. Informações adicionais
                    <br></br><br></br>
                    Nosso site pode não funcionar corretamente se todos os cookies forem desativados. Se você excluir os cookies do seu navegador, eles serão inseridos novamente após o seu consentimento quando você visitar nossos sites outra vez.
                    <br></br><br></br>
                    Estamos empenhados em manter seguras a suas informações pessoais. Para mais informações sobre o processamento de dados pessoais, por favor consulte a nossa Política de Privacidade (https://grupoviamaquinas.com.br/politica).
                    <br></br><br></br>
                    Se você tiver alguma pergunta, comentário ou reclamação sobre como lidamos com seus dados, gostaríamos de ouvi-la. Por favor, entre em contato conosco através do e-mail <Link href="mailto:rubens@viamaquinas.com.br">rubens@viamaquinas.com.br</Link>.
                    <br></br><br></br>
                    Esta Política de Cookies foi atualizada pela última vez em 07 de Novembro de 2022.
                </div>

            </div>
            <Footer />
        </>
    )
}

export const Conditions = (props) => {
    return (
        <>
            <Header filter={false} />
            <div className="container container-terms align-items-start">
                <div className='terms__title'>Termos & Condições de Uso</div>
                <div className='terms__updated'>Última atualização em 10 de março 2023</div>
                <div>
                    A "VIA MAQUINAS COMERCIO VAREJISTA LTDA" é uma empresa pertencente ao "Grupo Via Máquinas", que tem por objetivo atuar como intermediadora na compra e venda de máquinas e equipamentos agrícolas ("linha verde") e de construção civil ("linha amarela"), (Caminhões, veículos leves e utilitários, implementos de qualquer natureza),  oferecendo através de seu Site um canal de aproximação entre vendedores e compradores, permitindo através das plataformas disponibilizadas, a colocação à venda de produtos dos segmentos mencionados e a negociação de preços e diferentes meios de pagamento.
                    <br></br><br></br>
                    Para operar na plataforma disponibilizada, todos os USUÁRIOS devem aceitar os Termos e Condições e a <Link href="/terms/privacy" target="_blank">Política de Privacidade</Link> .
                    <br></br><br></br>
                    Cada USUÁRIO é responsável pelos dados pessoais fornecidos no momento do cadastro e pelas informações de produtos que disponibilize à venda, assim como pela guarda, proteção e uso de seu usuário e senha.
                    <br></br><br></br>


                    TERMOS E CONDIÇÕES GERAIS DE USO DO SITE
                    <br></br><br></br>


                    Os presentes termos e condições gerais de uso se destinam a regulamentar a relação entre a empresa VIA MAQUINAS COMERCIO VAREJISTA LTDA (doravante "VIA MÁQUINAS - VAREJO") e o USUÁRIO do presente site (outlet.viamaquinas.com.br) e dos serviços oferecidos por aquela.
                    <br></br><br></br>
                    O USUÁRIO ao navegar e/ou se cadastrar no presente site, concorda e aceita os presentes Termos e Condições Gerais, os quais possuem caráter obrigatório e vinculante.
                    <br></br><br></br>


                    <b>1 - DA ACEITAÇÃO PELO USUÁRIO</b>
                    <br></br>
                    O USUÁRIO, ao utilizar e/ou se cadastrar no presente site, declara:
                    <br></br><br></br>
                    (i) ter capacidade, autoridade e legitimidade para assumir todas as responsabilidades e obrigações decorrentes deste instrumento e da legislação pertinente;
                    <br></br>
                    (ii) que se estiver em representação de uma empresa, possui capacidade e autorização para contratar em nome daquela;
                    <br></br>
                    (ii) que leu, compreendeu e aceitou ingressar, visitar, utilizar e/ou registrar-se nesta plataforma;
                    <br></br>
                    (iii) que concorda com Políticas de Privacidade e demais anexos e documentos que venham a ser incorporados e previamente publicados no Site;
                    <br></br>
                    (iv) que renuncia a qualquer disposição contrária aos presentes Termos e Condições Gerais.
                    <br></br><br></br>


                    <b>2 - DOS SERVIÇOS PRESTADOS</b>
                    <br></br>
                    2.1 - Os Serviços objeto dos presentes Termos e Condições consiste na prestação de serviços de intermediação digital de compra e venda, entre USUÁRIOS cadastrados, de máquinas e equipamentos agrícolas ("linha verde") e de construção civil ("linha amarela") (Caminhões, veículos leves e utilitários, implementos de qualquer natureza) por meio de plataforma específica no Site administrada pela "VIA MÁQUINAS - VAREJO".
                    <br></br>
                    2.2 - Com exceção da intermediação, a "VIA MÁQUINAS - VAREJO" não oferece produtos próprios nem é a vendedora dos produtos exibidos no Site.
                    <br></br>
                    2.3 - A "VIA MÁQUINAS - VAREJO" não assume obrigação alguma com respeito aos USUÁRIOS e anúncios veiculados, limitando-se tão somente a manter disponível o espaço virtual para anúncio e a viabilizar a intermediação da comercialização por meio de sua plataforma.
                    <br></br>
                    2.4 - As características dos bens anunciados são fornecidas pelos próprios USUÁRIOS ofertantes, de modo que a "VIA MÁQUINAS - VAREJO" NÃO CONCEDE NENHUMA GARANTIA nem se entenderá que existem garantias implícitas de qualidade, de êxito de venda, de resultados, ou idoneidade para um propósito particular.
                    <br></br>
                    2.5 - A "VIA MÁQUINAS - VAREJO" NÃO figura como garantidora, fiadora ou avalista dos USUÁRIOS Compradores, não se responsabilizando pela inexecução do pagamento do preço por estes.
                    <br></br>
                    2.5 - A "VIA MÁQUINAS - VAREJO" NÃO se responsabiliza pela emissão de notas fiscais acerca dos produtos adquiridos, pela entrega ou disponibilização dos itens postos a comercialização pelos USUÁRIOS VENDEDORES no site, tampouco por intermediar a devolução dos itens após a sua retirada ou recebimento.
                    <br></br><br></br>


                    <b>3 - DO REGISTRO E CONTA DO USUÁRIO</b>
                    <br></br><br></br>
                    3.1 - Para utilizar determinados serviços disponibilizados, o USUÁRIO deverá preencher o formulário de registro com os dados que lhe forem requeridos, com informação pessoal e/ou da pessoa jurídica que representa de maneira exata, precisa e verdadeira, sendo a única responsável pela certeza dos dados fornecidos.
                    <br></br>
                    3.2 - O USUÁRIO deverá manter seus dados sempre atualizados.
                    <br></br>
                    3.3 - A conta é pessoal, única e intransferível, não podendo em nenhuma circunstância ser vendida ou transferida para outra pessoa. A conta é acessada com a senha pessoal de segurança escolhida pelo próprio USUÁRIO e deverá ser mantida sob estrita confidencialidade.
                    <br></br>
                    3.4 - O USUÁRIO é o único responsável pelas operações realizadas na sua conta. Caso identifique o uso não autorizado de sua conta, ela deverá notificar imediatamente a "VIA MÁQUINAS - VAREJO".
                    <br></br>
                    3.5 - É vedado o uso de mais de uma conta de USUÁRIO por pessoa.
                    <br></br>
                    3.6 - O Cadastro de Pessoa Física deverá ser feito obrigatoriamente com o preenchimento dos seguintes campos: Nome Completo, CPF, Sexo, Data de Nascimento, RG, Telefone Celular, Telefone Residencial, Telefone Comercial, Usuário, E-mail, Senha, Confirmar Senha, CEP, Endereço, Número, Complemento, Estado, Cidade e Bairro.
                    <br></br>
                    3.7 - O Cadastro de Pessoa Jurídica deverá ser feito obrigatoriamente com o preenchimento dos seguintes campos: Razão Social, CNPJ, IE, Nome do Responsável, Telefone Celular, Telefone Residencial, Telefone Comercial, Usuário, E-mail, Senha, Confirmar Senha, CEP, Endereço, Número, Complemento, Estado, Cidade, Bairro.
                    <br></br>
                    3.8 - A "VIA MÁQUINAS - VAREJO" reserva-se no direito de solicitar dados ou informações adicionais para o caso de USUÁRIOS que realizam transações que exijam operações adicionais exigidas por lei ou pelas políticas atuais ou futuras do Site, ou, ainda, documentação ou comprovantes para confirmar a veracidade e exatidão dos dados pessoais e/ou societários fornecidos pelo USUÁRIO.
                    <br></br>
                    3.8.1 - O USUÁRIO que não apresentar a confirmação de dados pessoais, após solicitado autorizará a "VIA MÁQUINAS - VAREJO" a suspender, temporária ou definitivamente a respectiva conta. Neste caso, serão suspensos ou cancelados todos os anúncios publicados, assim como as ofertas realizadas, sem que isso gere nenhum tipo de direito a ressarcimento.
                    <br></br>
                    3.9 - A "VIA MÁQUINAS - VAREJO" adotará critérios e parâmetros próprios às avaliações dos cadastros solicitados, cabendo exclusivamente àquela a decisão de aprovação ou não do cadastro para acesso à sua plataforma e respectivas funcionalidades, cancelar o cadastro de qualquer USUÁRIO, a qualquer tempo e ao seu exclusivo critério, sem que esteja obrigada a comunicar ou expor as razões de sua decisão, e sem que isso gere algum direito a indenização ou ressarcimento ao USUÁRIO.
                    <br></br>
                    3.10 - Nomes de USUÁRIOS considerados ofensivos, assim como que contenham dados pessoais do USUÁRIO ou alguma URL ou endereço eletrônico serão excluídos.
                    <br></br>
                    3.11 - Não poderá se registrar novamente no site o USUÁRIO que tenha sido desativado previamente.
                    <br></br>
                    3.12 - A "VIA MÁQUINAS - VAREJO" poderá, ainda, entrar em contato com os USUÁRIOS por chat, e-mail, Whastapp ou mesmo ligação telefônica para perguntar sobre a experiência na plataforma e oferecer consultoria.
                    <br></br>
                    3.13 - O USUÁRIO reconhece que é o único responsável pela veracidade e conteúdo disponível em seu anúncio ou publicação, eximindo a "VIA MÁQUINAS - VAREJO" de qualquer responsabilidade nesse sentido, assim como declara, nos termos da lei.
                    <br></br>
                    3.14 - A "VIA MÁQUINAS - VAREJO" se reserva no direito de proceder ao imediato cancelamento do registro do USUÁRIO, ou a exclusão, edição ou modificação do anúncio, caso verificada:
                    <br></br>
                    (i) infração a qualquer das disposições estabelecidas nestes Termos e Condições, a lei, a moral, a ordem pública, os bons costumes e em particular, a infração dos direitos de propriedade intelectual, de dados pessoais, os direitos à honra, à intimidade e à imagem, e dos direitos de propriedade.
                    <br></br>
                    (ii) a suspeita ou confirmação de atividade fraudulenta, enganosa, incomum, que induza ao erro, contra a natureza do Site e os Serviços ali disponíveis.
                    <br></br>
                    (iii) o atraso acima de 30 (trinta) dias corridos no pagamento dos Serviços tarifados, contados a partir da data da fatura.
                    <br></br><br></br>


                    <b>4 - DA UTILIZAÇÃO DO SITE</b>
                    <br></br>
                    4.1 -  A plataforma "OUTLET" disponibilizada pela "VIA MÁQUINAS - VAREJO" permite ao USUÁRIO vendedor anunciar as máquinas e equipamentos do segmento descrito à Cláusula 2.1, pelo preço pretendido, permitindo, ainda, que outros usuários interessados em adquirir o bem anunciado façam propostas para aquisição, as quais serão intermediadas por consultores em vendas da "VIA MÁQUINAS - VAREJO".
                    <br></br>
                    4.2 - O USUÁRIO se compromete a utilizar o Site de acordo com os presentes Termos e Condições, com a lei, a moral, os bons costumes, a ordem pública, e sob seu exclusivo risco e responsabilidade. Igualmente, compromete-se fazer um uso adequado do Site e a não empregá-lo para realizar atividades ilícitas, fraudulentas, que atentem contra os direitos de terceiros e/ou que infrinjam o regulamento sobre propriedade intelectual, ou qualquer outra norma aplicável.
                    <br></br>
                    4.3 - O USUÁRIO compromete-se a:
                    <br></br>
                    (i) a utilizar o Site de acordo com os presentes Termos e Condições, com a lei, a moral, os bons costumes, a ordem pública, e sob seu exclusivo risco e responsabilidade;
                    <br></br>
                    (ii) fazer o uso adequado do Site e não o empregar para realizar atividades ilícitas, fraudulentas, que atentem contra os direitos de terceiros e/ou que infrinjam o regulamento sobre propriedade intelectual, ou qualquer outra norma aplicável;
                    <br></br>
                    (iii) Não postar ou difundir quaisquer informações e conteúdos falsos, ambíguos, inconvenientes ou inexatos de forma que induza a erro aos receptores da informação;
                    <br></br>
                    (iv) Não fazer mais de um anúncio para o mesmo produto;
                    <br></br>
                    (v) Não criar ou utilizar mais de um usuário para a anunciar o mesmo produto ou, de alguma forma, utilizá-lo para criar falsa concorrência em venda ou proposta de produtos;
                    <br></br>
                    (vi) Fornecer dados reais a seu respeito no momento do cadastro e a respeito do bem colocado à comercialização, com informações suficientes à caracterização do produto, sua individualização, informando ainda a ausência de peças, vícios ou defeitos correlacionados ao produto que lhe possam diminuir o valor e/ou funcionalidade, de modo que permita aos USUÁRIOS interessados compreender a qualidade e quantidade do bem;
                    <br></br>
                    (vii) Não realizar atividades ou publicações enganosas, fraudulentas, intimidantes ou ameaçadoras, nem que induzam ao erro de outras pessoas ou usuários;
                    <br></br>
                    (viii) Não revelar dados pessoais de outros usuários através de qualquer meio;
                    <br></br>
                    (ix) Não utilizar dados pessoais de usuários, para fins fraudulentos;
                    <br></br>
                    (x) Não anunciar, publicar ou vender artigos ou produtos proibidos pelos Termos e Condições, pelas demais Políticas da "VIA MÁQUINAS - VAREJO" ou pelas normas vigentes;
                    <br></br>
                    (xi) Não interferir nas negociações entre outros usuários;
                    <br></br>
                    (xii) Não agredir, caluniar, injuriar ou difamar outros usuários.
                    <br></br>
                    (xiii) Não postar ou difundir conteúdos contrários à honra, à intimidade ou à imagem ou propaganda de caráter racista, xenofóbico, pornográfico, ou de apologia ao crime.
                    <br></br>
                    (xiv)  Não postar ou difundir na rede programas de dados capazes de provocar danos nos sistemas informáticos do provedor de acesso, seus fornecedores ou terceiros usuários da rede de Internet.
                    <br></br>
                    (xv) Não postar, difundir, transmitir ou pôr à disposição de terceiros qualquer tipo de informação, elemento ou conteúdo que constitua publicidade ilícita ou desleal;
                    <br></br>
                    (xvi) Não postar, difundir, transmitir ou pôr à disposição de terceiros qualquer tipo de informação, elemento ou conteúdo que suponha uma violação do decreto das comunicações e da legislação de dados de caráter pessoal;
                    <br></br>
                    (xvii) Não se permite ainda o uso de nenhum dispositivo, software ou outro recurso que interfira nas atividades e operações da "VIA MÁQUINAS - VAREJO", assim como nos anúncios, descrições, contas ou bases de dados.
                    <br></br>
                    4.4 - O USUÁRIO que não observar as obrigações mencionadas nos presentes Termos e Condições de Uso, na Política de Privacidade e/ou outros instrumentos que regulamentem a relação entre as partes, ou, ainda, a qualquer disposição prevista em lei, estará sujeito à suspensão ou desativação da publicação e/ou de sua inscrição como USUÁRIO do Site.
                    <br></br>
                    4.5 - No caso da suspensão ou desativação de um USUÁRIO, todos os itens que tiverem sido publicados serão removidos do sistema, ocasião em que não se devolverão eventuais encargos ou taxas pagos em favor da "VIA MÁQUINAS - VAREJO".
                    <br></br>
                    4.6 - Além da suspensão ou desativação mencionados, a "VIA MÁQUINAS - VAREJO" se resguarda no direito de promover em face do USUÁRIO infrator eventuais ações, no âmbito administrativo e/ou judicial, nas esferas cível e/ou criminal, objetivado a penalização e o reparo de prejuízos que possa causar à "VIA MÁQUINAS - VAREJO" e/ou aos demais usuários e/ou a terceiros.
                    <br></br><br></br>


                    <b>5 - DAS PUBLICAÇÕES DE MÁQUINAS E EQUIPAMENTOS E COMPROMISSO DE VENDA ENTRE OS INTERESSADOS</b>
                    <br></br>
                    5.1 - O USUÁRIO VENDEDOR encaminhará à publicação a máquina ou equipamento, apresentando breve descrição do item, de modo a permitir a correta caracterização do produto, informando as características essenciais do modelo, série, acessórios, funcionalidades ou, sendo o caso, a ausência de peças, vícios ou defeitos que possam estar correlacionados ao produto, apresentando ainda o preço pretendido, por sua vez, o USUÁRIO COMPRADOR interessado pode perguntar ou contatar os consultores da "VIA MÁQUINAS - VAREJO" para obter informações acerca de forma de pagamento sem que configura compromisso.
                    <br></br>
                    5.1.1 - A publicação poderá contemplar textos descritivos, fotografias e outros conteúdos e condições pertinentes que correspondam ao bem publicado, sendo, no entanto, vedado a inserção de dados pessoais ou de contato (tais como números telefônicos, endereços de e-mail, endereços de páginas de internet ou outro dado que permita aos usuários a contatar ao USUÁRIO VENDEDOR fora do Site).
                    <br></br>
                    5.2 - O USUÁRIO COMPRADOR interessado pode através da plataforma da "VIA MÁQUINAS - VAREJO" efetuar proposta de compra apresentando o preço e condições de pagamento, ocasião em que os consultores da "VIA MÁQUINAS - VAREJO" submeterão a proposta ao USUÁRIO VENDEDOR, que poderá aceitá-la ou, querendo, apresentar contraproposta.
                    <br></br>
                    5.3 - Havendo a aceitação da proposta, serão os USUÁRIOS COMPRADOR e VENDEDOR imediatamente informados, considerando-se perfectibilizada a contratação, obrigando-se o USUÁRIO COMPRADOR a realizar o preço e o USUÁRIO VENDEDOR a entregar ou disponibilizar o bem, livre e desembaraçado, na forma e prazo ajustados na proposta aceita.
                    <br></br>
                    5.4 - Somente poderá fazer propostas ou questionamentos acerca dos itens anunciados o USUÁRIO devidamente cadastrado na plataforma.
                    <br></br>
                    5.5 - Somente poderá publicar o bem à venda, o USUÁRIO cadastrado que tenha a intenção e capacidade de vender o bem, ser proprietário ou estar autorizado pelo proprietário do bem para realizar a venda e ter a disponibilidade imediata do bem para sua entrega.
                    <br></br>
                    5.6 - É obrigação do USUÁRIO VENDEDOR o fornecimento do Nota Fiscal, registro de propriedade ou outro documento que permita ao USUÁRIO COMPRADOR registrar a aquisição do item perante os órgãos competentes, quando necessário.
                    <br></br>
                    5.7 - Salvo ajuste em contrário, será de responsabilidade do USUÁRIO VENDEDOR as despesas, encargos e tributos incidentes sobre o bem antes da entrega do produto ao USUÁRIO COMPRADOR, competindo a este as despesas encargos e tributos incidentes sobre o bem após o recebimento.
                    <br></br>
                    5.8 - A "VIA MÁQUINAS - VAREJO" não se responsabiliza pelas informações publicadas no site relativa a preços, qualidades e características dos bens e serviços, sua disponibilidade, condições de venda, restrições, as quais são fornecidas pelo próprio USUÁRIO VENDEDOR. Considerando que a "VIA MÁQUINAS - VAREJO" não é proprietária nem possuidora dos bens anunciados, não se responsabiliza pela entrega destes pelo USUÁRIO VENDEDOR. Da mesma maneira, a "VIA MÁQUINAS - VAREJO" não se responsabiliza pelo cumprimento do preço pelo USUÁRIO COMPRADOR.
                    <br></br><br></br>


                    <b>6 - DAS TARIFAS E COMISSÕES</b>
                    <br></br>
                    6.1 - O cadastro de usuário, acesso aos anúncios de bens publicados e contatos com os consultores vendedores da "VIA MÁQUINAS - VAREJO" são gratuitos, no entanto, a utilização por parte do USUÁRIO de alguns dos Serviços providos pela "VIA MÁQUINAS - VAREJO" implica o pagamento de Tarifa ou de Comissão, conforme plano de contratação para divulgação dos anúncios.
                    <br></br>
                    6.2 - A "VIA MÁQUINAS - VAREJO" poderá, a seu exclusivo critério e mediante comunicação prévia ao USUÁRIO cadastrado, modificar, mudar, adicionar, ou eliminar as tarifas ou comissões vigentes, em qualquer momento e sem prévio aviso
                    <br></br>


                    <b>7 - OBRIGAÇÕES DO USUÁRIO</b>
                    <br></br><br></br>
                    7.1 - São obrigações dos USUÁRIO VENDEDOR, dentre outras elencadas neste instrumento:
                    <br></br>
                    (i) Atualizar suas publicações em caso de eventuais modificações nos itens anunciados, principalmente a respeito de alterações de características essenciais, estado, quantidade, qualidade e preço;
                    <br></br>
                    (ii) Cumprir a oferta anunciada na forma que em que foi publicada;
                    <br></br>
                    (iii) Responder aos consultores da "VIA MÁQUINAS - VAREJO" acerca das propostas apresentadas e/ou informações solicitadas no prazo de até 24h (vinte e quatro horas);
                    <br></br>
                    (iv) Arcar com todos os impostos que lhe correspondam pelas operações realizadas no Site;
                    <br></br>
                    (v) Arcar com a comissão da "VIA MÁQUINAS - VAREJO" nos valores ajustados em contrato, quando da confirmação do negócio;
                    <br></br>
                    (v) Fornecer os documentos correspondentes à transferência de titularidades, tais como Notas Fiscais, Certidões de Registro, entre outros;
                    <br></br>
                    (vi) Proporcionar a restituição de valores recebidos pela venda do bem anunciado, em caso de arrependimento pelo adquirente, desde que este seja manifestado no prazo de 7 (sete) dias da data de recebimento/retirada do item pelo comprador ou preposto, ajustando diretamente com este a respeito da forma de devolução e quem será responsável pelos encargos de devolução.
                    <br></br>
                    (vii) assumir integralmente a responsabilidade e eventuais custos por eventuais obrigações decorrentes da compra e venda, isentando ou diligenciando para que a "VIA MÁQUINAS - VAREJO" não seja responsabilizada, arcando com as despesas relacionadas a condenações, acordos, custas processuais e honorários de advogados.
                    <br></br>


                    7.2 - São obrigações dos USUÁRIO COMPRADOR, dentre outras elencadas neste instrumento:
                    <br></br>
                    (i) Efetuar as propostas de compra ou solicitar as informações a respeito de um produto anunciado no site, mediante a utilização dos meios disponibilizados para contato com os consultores;
                    <br></br>
                    (ii) Responder aos consultores da "VIA MÁQUINAS - VAREJO" acerca das contrapropostas apresentadas pelo vendedor no prazo de até 24h (vinte e quatro horas), sob pena de perder eventual preferência na negociação que lhe venha a ser concedida;
                    <br></br>
                    (iii) Após aceita a proposta pelo vendedor, arcar com o preço na forma ajustada;
                    <br></br>
                    (iv) Manifestar no ato da retirada ou entrega do produto eventuais inconsistências em relação às características do produto que lhe foi disponibilizado e o anúncio efetuado pelo USUÁRIO VENDEDOR;
                    <br></br>
                    (v) Manifestar em até 7 (sete) dias do recebimento ou retirada do bem junto ao vendedor eventual direito de arrependimento, providenciando à imediata devolução do bem com as mesmas características recebidas, inclusive "horas de uso" ou "quilometragem" quando o bem dispor deste tipo de controle;
                    <br></br>
                    (vi) No caso da rescisão e devolução do bem o frete será por conta do comprador. Não devendo a VIA MÁQUINAS-VAREJO" reembolso relativo a fretes e demais despesas relativas ao transporte.
                    <br></br>
                    (vii) arcar com os impostos que lhe correspondam segundo a legislação vigente.
                    <br></br><br></br>


                    <b>8 - DAS LIMITAÇÕES DE RESPONSABILIDADE DA "VIA MÁQUINAS - VAREJO"</b>
                    <br></br>
                    8.1 - A "VIA MÁQUINAS - VAREJO" atua tão somente na intermediação de negócios, sendo mera auxiliar do comércio, não se responsabilizando pela qualidade, segurança ou regularidade das máquinas e equipamentos cadastrados, nem tampouco pela idoneidade de compradores e vendedores, ou a sua capacidade de pagar ou entregar os bens negociados.
                    <br></br>
                    8.2 - A "VIA MÁQUINAS - VAREJO" não se responsabiliza por danos ou prejuízos eventualmente sofridos por seus usuários em decorrência de problemas técnicos, eventuais falhas no sistema ou na conexão do USUÁRIO ou de seu site.
                    <br></br>
                    8.3 - As informações publicadas nos anúncios são fornecidas pelo próprio USUÁRIO VENDEDOR, sendo responsabilidade exclusiva deste a veracidade das informações e eventuais danos à terceiros e à "VIA MÁQUINAS - VAREJO".
                    <br></br>
                    8.4 - A "VIA MÁQUINAS - VAREJO" não assegura o cumprimento das propostas pelos usuários, tampouco a preferência na negociação, ficando a exclusivo critério do USUÁRIO VENDEDOR antes de aceita a oferta efetuada pelo USUÁRIO COMPRADOR, optar por outra com condições melhores.
                    <br></br>
                    8.5 - Os USUÁRIOS são livres para estipular o preço e condições de pagamento, de modo que a "VIA MÁQUINAS - VAREJO" não garante que os preços informados pelos USUÁRIOS VENDEDORES em seus anúncios correspondam ao efetivo preço de mercado, cumprindo ao USUÁRIO COMPRADOR diligenciar na conferência antes de efetuar a proposta de aquisição.
                    <br></br>
                    8.6 - Cumpre aos USUÁRIOS diligenciar na verificação da idoneidade da negociação, agindo com prudência e senso comum no momento de realizar operações, sendo que a mera confiança depositada por USUÁRIOS na "VIA MÁQUINAS - VAREJO" ou empresas do mesmo grupo não enseja qualquer responsabilidade desta na realização de ofertas e/ou operações entre usuários.
                    <br></br><br></br>


                    <b>9 - DO TRATAMENTO, COMPARTILHAMENTO E SEGURANÇA DE DADOS</b>
                    <br></br>
                    9.1 -  Considerando as disposições da Lei Geral de Proteção de Dados, o USUÁRIO expressamente consente à "VIA MÁQUINAS - VAREJO", desde que observadas as normas relativas à proteção de dados pessoais, ao sigilo bancário e à política de privacidade, o acesso e utilização de seus dados pessoais, discriminados no presente instrumento, em especial aqueles descritos à Cláusula 3.6 e 3.7, ou, ainda, eventualmente dados bancários, para as seguintes finalidades:
                    <br></br>
                    (i) Possibilitar que a "VIA MÁQUINAS - VAREJO" identifique e entre em contato com o USUÁRIO para fins de relacionamento comercial;
                    <br></br>
                    (ii) Possibilitar que a "VIA MÁQUINAS - VAREJO" elabore contratos e emita cobranças contra o USUÁRIO;
                    <br></br>
                    (iii) Possibilitar que a "VIA MÁQUINAS - VAREJO" envie ou forneça ao USUÁRIO seus produtos e serviços, de forma remunerada ou gratuita;
                    <br></br>
                    (iv) Possibilitar que a "VIA MÁQUINAS - VAREJO" utilize tais dados em Pesquisas de Mercado, elaboração de relatórios, desde que seja anonimizado;
                    <br></br>
                    (v) Possibilitar que a "VIA MÁQUINAS - VAREJO" utilize tais dados para suas peças de comunicação;
                    <br></br>
                    (vi) Possibilitar que a "VIA MÁQUINAS - VAREJO" utilize tais dados emissão de Notas Fiscais e documentos financeiros correlatos aos serviços de intermediação e taxas cobradas, ou ainda proceda a estornos;
                    <br></br>
                    (vii) Compartilhar, nos casos de conclusão do negócio entre os usuários, que a "VIA MÁQUINAS - VAREJO" os dados à terceira empresa ou pessoa responsável por emitir o faturamento, contrato da operação, proceder à cobranças, sejam administrativas ou judiciais.
                    <br></br>
                    9.2 - A "VIA MÁQUINAS - VAREJO" se responsabiliza pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger os dados, inclusive pessoais e sensíveis, de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
                    <br></br>
                    9.3 - Em conformidade ao art. 48, da Lei n.º 13.709 de 14 de Agosto de 2018, a "VIA MÁQUINAS - VAREJO" comunicará ao USUÁRIO e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao USUÁRIO.
                    <br></br>
                    9.4 - A "VIA MÁQUINAS - VAREJO" poderá manter e tratar os dados pessoais do USUÁRIO durante todo o período em que os mesmos forem pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.
                    <br></br>
                    9.5 - O USUÁRIO fica ciente de que o requerimento via e-mail ou correspondência a "VIA MÁQUINAS - VAREJO" requisitando a eliminação de seus dados pessoais não anonimizados ensejará óbice na efetivação de propostas nos bens veiculados no site de da empresa, bem como na aquisição de qualquer produto lá noticiado.
                    <br></br>
                    9.6 - A aceitação destes termos e condições de uso confere à "VIA MÁQUINAS - VAREJO" a prerrogativa de monitorar, editar, revelar, se recusar a publicar e remover, a qualquer tempo e por qualquer razão, informações de usuários informadas ao site da "VIA MÁQUINAS - VAREJO", ao seu livre critério e que esteja em dissonância com este termo e com a política segurança do site.
                    <br></br>
                    9.7 - A "VIA MÁQUINAS - VAREJO" não se responsabiliza pelas informações inverídicas prestadas pelo USUÁRIO.
                    <br></br><br></br>


                    <b>10 - ALTERAÇÕES E ATUALIZAÇÕES DOS PRESENTES TERMOS E CONDIÇÕES GERAIS DE USO DO SITE</b>
                    <br></br>
                    10.1 - Todas as alterações que vierem a ser efetuadas nestes Termos e Condições Gerais De Uso, ou seus anexos, serão publicadas após o envio aos usuários por e-mail informativo, no endereço por estes informados no ato do cadastro.
                    <br></br>
                    10.2 - Presumir-se-ão aceitas todas as alterações não recusadas expressamente pelo USUÁRIO no prazo de 7 (sete) dias, contados do envio da mensagem informativa de alteração contratual.
                    <br></br>
                    10.3 - O uso dos serviços após a publicação das alterações contratuais acarretará na presunção de conhecimento e aceitação das alterações por parte do USUÁRIO.
                    <br></br>
                    10.4 - Se o USUÁRIO recusar os novos Termos e Condições, deverá abster-se de utilizar o Site e/os Serviços por ele oferecidos. Adicionalmente, se o USUÁRIO se encontrar registrado no Site e não estiver de acordo com as modificações efetuadas, poderá solicitar o cancelamento de seu cadastro.
                    <br></br>
                    10.5 - A "VIA MÁQUINAS - VAREJO" se reserva no direito de encerrar, modificar ou suspender qualquer parte do "site", inclusive conteúdo, ferramentas e tempo de acesso parcial ou integral oferecidos ao USUÁRIO, independentemente do motivo, sem que isto implique em qualquer direito à indenização.
                    <br></br><br></br>
                    <b>11 - DOS ANEXO</b>S
                    <br></br>
                    Formam parte integral e inseparável dos Termos e Condições, todos os anexos, onde se detalham Políticas, Termos e Condições de diferentes Serviços oferecidos no Site.
                    <br></br><br></br>
                    <b>12 - DO FORO</b>
                    <br></br>
                    Toda e qualquer controvérsia que surgir da interpretação ou execução dos presentes Termos e Condições será resolvida pelo foro da Comarca de Balneário Camboriú, estado de Santa Catarina, renunciando as partes a qualquer outro, ainda que mais favorável.
                </div>
            </div>
        </>
    );
};
export default Privacy;