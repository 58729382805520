import Header from '../components/Layout/Header';
import Attributes from '../components/Attributes';
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import parse from "html-react-parser";
import Rating from '@mui/material/Rating';
import * as CurrencyFormat from 'react-currency-format';
import { CircularProgress, Link, Skeleton } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import MetaPixel from '../components/MetaPixel';
import CardArrows from '../components/Home/CardArrows';
import CardEquipment from '../components/Home/CardEquipment';
import Footer from '../components/Layout/Footer';
import CardEquipmentSkeleton from '../components/Home/CardEquipmentSkeleton';
import Contact from '../components/Contact';
import Modal from '../components/Modal';
import InputText from '../components/Form/InputText';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputMask from "react-input-mask";
import { useLocation, useNavigate } from 'react-router-dom'
import { useOffer, useProductInfo, useUserApproved, useUserInfo } from '../utils/services/queries';
import { useAuth } from '../utils/providers/auth';
import jwtDecode from 'jwt-decode';
import Textarea from '../components/Form/Textarea';
import image1 from "../assets/img/IMG_2813.JPG";
import image2 from "../assets/img/IMG_2815.JPG";
import image3 from "../assets/img/IMG_2817.JPG";
import image4 from "../assets/img/IMG_2819.JPG";

const Equipment = (props) => {
    const product_id = window.location.pathname.split("/")[2];
    const navigate = useNavigate();
    const { user, setUser } = useAuth();
    const { data, isLoading } = useProductInfo(product_id);
    const { mutate, isLoading: isLoadingOffer } = useOffer();

    const location = useLocation();
    const [cookies, setCookie] = useCookies([]);

    const [showOffer, setShowOffer] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [similar, setSimilar] = useState([]);

    //Others
    const [value, setValue] = React.useState('1');
    const [equipment, setEquipment] = React.useState([]);
    const [attributes, setAttributes] = React.useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [sending, setSending] = React.useState(false);

    //Oferta
    const [priceOffer, setPriceOffer] = React.useState('');
    const [observation, setObservation] = React.useState('');
    const [terms, setTerms] = React.useState('');

    var settings = {
        centerMode: false,
        infinite: false,
        centerPadding: '0px',
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <CardArrows customClass="right-[-12px]" type="right" />,
        prevArrow: <CardArrows customClass="left-[-12px]" type="left" />,
        responsive: [{
            breakpoint: 768,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '0.45rem',
                slidesToShow: 1
            }
        }]
    };

    function openOffer() {
        setShowOffer(true)
        /*if (user !== null && user.approved)
            setShowOffer(true)
        else if (user !== null)
            navigate('/register-step-2')
        else
            navigate('/login')*/
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }


    const { enqueueSnackbar } = useSnackbar();

    async function sendOffer() {
        if (!terms) {
            enqueueSnackbar('Você precisa aceitar os termos & condições e a política de privacidade do site!', { variant: 'error', autoHideDuration: 7000 });
            return;
        }

        const params = { product_id: window.location.pathname.split("/")[2], value: priceOffer, observation: observation }

        mutate(params, {
            onSuccess: (res) => {
                enqueueSnackbar('Proposta enviada com sucesso, assim que possível entraremos em contato!', { variant: 'success', autoHideDuration: 7000 });
                setShowOffer(false);
                setPriceOffer('');
            },
            onError: (res) => {
                enqueueSnackbar('Preencha todos os campos corretamente!', { variant: 'error', autoHideDuration: 7000 });
            }
        })
    };

    const equipment_id = location.pathname.split("/")[2];

    useEffect(() => {
        setEquipment([]);

        //Salve no cookie um array com os IDS dos lotes visitados para a aba 'Visitados por você'
        let equipments_visited = cookies['equipments_visited'] ? cookies['equipments_visited'] : [];
        if (!(equipments_visited.indexOf(equipment_id) >= 0)) {
            equipments_visited.unshift(equipment_id)
            var expireCookie = new Date();
            expireCookie = expireCookie.setFullYear(expireCookie.getFullYear() + 1);;
            setCookie('equipments_visited', equipments_visited, { path: '/', expire: expireCookie });
        }

        window.scrollTo(0, 0)

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, [equipment_id]);




    return (
        <>
            <Header />
            <div className="mx-auto max-w-6xl px-8 lg:px-6 pt-5">
                <MetaPixel data={equipment} />
                <div className="flex flex-wrap lg:flex-nowrap gap-2 lg:gap-[3rem] pb-3">
                    <div className="w-full lg:w-3/6">
                    <Carousel
  indicators={false}
  navButtonsAlwaysVisible={true}
  animation={'slide'}
  cycleNavigation={true}
  autoPlay={false}
  duration={500}
>
  <div
    key={1}
    style={{
      borderRadius: '10px',
      background: `url("${image1}") no-repeat`,
      height: width > 600 ? "370px" : "330px",
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }}
  >
    <div className="water__markEquipment"></div>
  </div>
  <div
    key={2}
    style={{
      borderRadius: '10px',
      background: `url("${image2}") no-repeat`,
      height: width > 600 ? "370px" : "330px",
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }}
  >
    <div className="water__markEquipment"></div>
  </div>
  <div
    key={3}
    style={{
      borderRadius: '10px',
      background: `url("${image3}") no-repeat`,
      height: width > 600 ? "370px" : "330px",
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }}
  >
    <div className="water__markEquipment"></div>
  </div>
  <div
    key={4}
    style={{
      borderRadius: '10px',
      background: `url("${image4}") no-repeat`,
      height: width > 600 ? "370px" : "330px",
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }}
  >
    <div className="water__markEquipment"></div>
  </div>
</Carousel>
                    </div>
                    <div className="w-full lg:w-3/6">
                        <div className='text-[1.5rem] font-black pb-3 text-[#3a3a3a]' >
                            <>PÁ CARREGADEIRA JOHN DEERE 444G ANO 2022</>

                        </div>
                        <div>
                            {
                                <div className="list-none">
                                    <li className="mb-3">Potência: <b>126HP</b></li>
                                    <li className="mb-3">Horas trabalhadas: <b>2500 horas</b></li>
                                </div>

                            }
                            {!(equipment.sold === 'S' || equipment.status === 'D') ?
                                <div className="grid justify-start">
                                    <button className='mt-2 py-3 px-4 text-white rounded uppercase font-semibold bg-gradient-to-b from-[#f5ac29] to-[#CE9730] hover:opacity-75' onClick={() => window.open('https://wa.me/5547999371903?text=Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20P%C3%81%20CARREGADEIRA%20JOHN%20DEERE%20444G%202022%20que%20est%C3%A1%20dispon%C3%ADvel%20no%20Rental.')}>
                                        Solicitar orçamento
                                    </button>

                                </div> : <Button className="error_button" variant="contained" size="large">
                                    Equipamento vendido
                                </Button>}
                        </div>
                    </div>
                </div>
                <Box sx={{ width: '100%', typography: 'body1', fontFamily: 'outfit' }} className="font-outfit">
                    <TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', color: 'yellow' }}>
                            <TabList aria-label="lab API tabs example">
                                <Tab label="Descrição" value="1" className='font-[outfit!important]' sx={{
                                    color: '#3a3a3a', // Color for the unselected state
                                    '&.Mui-selected': { // Target the selected state
                                        color: '#f5ac29' // Blue color for the selected state
                                    }
                                }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div className="font-outfit mb-2">
                                <div className="font-semibold">Especificações:</div>
                                <div className="grid lg:grid-cols-2">
                                    <div className="w-full">
                                        <div className="mb-2">Categoria:<br /> <b>PÁ CARREGADEIRA</b><br /></div>
                                        <div className="mb-2">Fabricante:<br /> <b>JOHN DEERE</b><br /></div>
                                        <div className="mb-2">Modelo:<br /> <b>444G</b><br /></div>
                                        <div className="mb-2">Localização:<br /> <b>Goiania - GO</b><br /></div>
                                    </div>
                                    <div className="w-full">
                                        <div className="mb-2">Ano:<br /> <b>2022</b><br /></div>
                                        <div className="mb-2">Estado de conservação:<br /> <b>BOM ESTADO</b><br /></div>
                                        <div className="mb-2">Horas trabalhadas:<br /> <b>2500</b><br /></div>
                                        <div className="mb-2">Potência:<br /> <b>126 HP</b><br /></div>
                                    </div>
                                </div>
                                {data?.data?.product?.description ?
                                    <React.Fragment>{parse(data?.data?.product?.description)}</React.Fragment> : ''
                                }
                            </div>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>

            {/*<div className="mx-auto max-w-6xl px-8 lg:px-6 pb-3">
                <div className="font-semibold text-[1.5rem] px-2 text-[#353535]"><i className="fa-solid fa-fire" style={{ color: 'rgb(233 119 41)' }}></i> Equipamentos Similares</div>

                <Slider {...settings}>
                    {(similar.length > 0) ?
                        similar.map((item, i) => {
                            return (
                                <CardEquipment data={item} key={i} type="sm" />
                            )
                        })
                        : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => {
                            return (
                                <CardEquipmentSkeleton key={i} />
                            )
                        })}

                </Slider>
            </div>*/}
            <Contact setShow={setShowContact} show={showContact} type="contato" />
            <Modal isVisible={showOffer} onClose={() => setShowOffer(false)}>
                <div className="p-4">
                    <div className="text-[1.3rem] font-semibold" style={{ marginBottom: 15, textAlign: 'center', color: "#212529" }}>Faça sua oferta!</div>
                    <div className="mt-2 mb-2">

                        <CurrencyTextField
                            className="w-full border p-4 text-sm "
                            variant="outlined"
                            value={priceOffer}
                            currencySymbol="R$"
                            outputFormat="string"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            onChange={(event, value) => setPriceOffer(value)}
                        />
                        <Textarea
                            value={observation}
                            onChange={(i) => setObservation(i.target.value)}
                            className="w-full border px-3 mt-3"
                            placeholder="Escreva aqui..."
                            multiline
                            rows={4}
                            maxRows={4}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={terms} onChange={(i, value) => setTerms(value)} name="Aceitar termos" />
                            }
                            label={<div style={{ fontSize: 12 }}>Eu aceito os <Link href="/terms/conditions" target="_blank">Termos & Condições</Link> e a <Link href="/terms/privacy" target="_blank">Política de Privacidade</Link> do site.</div>}
                        />
                    </div>
                    <Button className='w-full' variant="contained" size="large" onClick={sendOffer}>
                        {(isLoadingOffer) ? <CircularProgress size="1.5rem" /> : 'Enviar oferta'}
                    </Button>
                </div>
            </Modal>
            <Footer />
        </>
    );
}

export default Equipment;