import React, { useState } from "react";
import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack';
import Link from '@mui/material/Link';
import Modal from "./Modal";
import InputText from "./Form/InputText";
import Textarea from "./Form/Textarea";
import InputTextMask from "./Form/InputTextMask";
import { useContact } from "../utils/services/queries";

export default function Contact(props) {
    const [sending, setSending] = React.useState(false);
    const handleClose = () => props.setShow(false);
    const [width, setWidth] = useState(window.innerWidth);
    const { type } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { mutate, isLoading } = useContact();

    //Contato
    const [contactName, setContactName] = React.useState('');
    const [contactMail, setContactMail] = React.useState('');
    const [contactPhone, setContactPhone] = React.useState('');
    const [contactMessage, setContactMessage] = React.useState('');
    const [contactTerms, setContactTerms] = React.useState(true);
    const [acceptContact, setAcceptContact] = React.useState(true);
    function setValues(name, value) {
        setContactPhone(value);
    }

    async function sendContact() {
        const params = { name: contactName, email: contactMail, phonenumber: contactPhone, message: contactMessage, accept_contact: acceptContact, type: type }

        if (!contactTerms) {
            enqueueSnackbar('Você precisa aceitar os termos & condições e a política de privacidade do site!', { variant: 'error', autoHideDuration: 7000 });
            return;
        }

        mutate(params, {
            onSuccess: (res) => {
                enqueueSnackbar('Mensagem enviada com sucesso, assim que possível entraremos em contato!', { variant: 'success', autoHideDuration: 7000 });
                handleClose();
                setContactMessage('');
                setContactPhone('');
                setContactMail('');
                setContactName('');
            },
            onError: (res) => {
                enqueueSnackbar("Preencha todos os campos!", { variant: 'error', autoHideDuration: 7000 });
            }
        })

    };


    return (
        <Modal isVisible={props.show} onClose={() => props.setShow(false)}>
            <div className="p-6">
                <div className="text-[1.3rem] font-semibold" style={{ marginBottom: 15, textAlign: 'center', color: "#212529" }}>Fale conosco!</div>
                <div className="mb-3 text-black">
                    <InputText
                        className="w-full border text-sm"
                        placeholder="Nome completo"
                        value={contactName}
                        size={(width > 600) ? "medium" : "small"}
                        onChange={(i, value) => setContactName(i.target.value)}
                    />
                </div>
                <div className="mb-3 text-black">
                    <InputText
                        className="w-full border text-sm"
                        placeholder="E-mail"
                        value={contactMail}
                        size={(width > 600) ? "medium" : "small"}
                        onChange={(i, value) => setContactMail(i.target.value)}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <InputTextMask
                        className="w-full border text-sm"
                        mask={"(99) 99999-9999"}
                        placeholder="Telefone"
                        setValue={setValues}
                    />
                </div>
                <div className="col-md-12">
                    <Textarea
                        value={contactMessage}
                        onChange={(i) => setContactMessage(i.target.value)}
                        className="w-full border px-3"
                        placeholder="Escreva aqui..."
                        size={(width > 600) ? "medium" : "small"}
                        multiline
                        rows={4}
                        maxRows={4}
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox checked={acceptContact} onChange={(i, value) => setAcceptContact(value)} name="Aceitar termos" />
                    }
                    label={<div className="font-outfit text-[12px] text-[#000]">Eu aceito receber contato através das informações preenchidas acima.</div>}
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={contactTerms} onChange={(i, value) => setContactTerms(value)} name="Aceitar termos" />
                    }
                    label={<div className="font-outfit text-[12px] text-[#000]">Eu aceito os <Link href="/terms/conditions" target="_blank">Termos & Condições</Link> e a <Link href="/terms/privacy" target="_blank">Política de Privacidade</Link> do site.</div>}
                />
                <Button className='w-full' variant="contained" size="large" onClick={sendContact}>
                    {(isLoading) ? <CircularProgress size="1.5rem" /> : 'Enviar'}
                </Button>
            </div>

        </Modal>
    )
}