import React, { useEffect, useState } from "react";

import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import logo from "../assets/img/new_logo.png";
import header from "../assets/img/header_outlet.png";
import banner from "../assets/img/bugio-banner.png";
import bannerConsulta from "../assets/img/bannerconsulta.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSnowplow, faTractor } from "@fortawesome/free-solid-svg-icons";
import CardEquipment from "../components/Home/CardEquipment";
import CardNews from "../components/Home/CardNews";
import Footer from '../components/Layout/Footer';
import CardEquipmentSkeleton from "../components/Home/CardEquipmentSkeleton";
import SelectConsulti from "../components/Consulti/SelectConsulti";
import InputText from "../components/Form/InputText";
import Spinner from "../components/Spinner";
import { useSnackbar } from "notistack";
import CurrencyFormat from "react-currency-format";
import Navbar from "../components/Layout/Navbar";
import { useConsulti } from "../utils/services/queries";

const Consulti = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies([]);
    const { mutate, isLoading } = useConsulti();
    const [sending, setSending] = useState(true);
    const [sendConsulti, setSendConsulti] = useState({
        sector_id: 0,
        segment_id: 0,
        category_id: 0,
        manufacturer_id: 0,
        model_id: 0,
        year: 0,
        state: ""
    })
    const [namesSelected, setNamesSelected] = useState({
        category_id: 0,
        manufacturer_id: 0,
        model_id: 0,
        year: 0,
        state: ""
    });
    const [searchSelect, setSearchSelect] = useState(false);

    //Selects
    const [sector, setSector] = useState([]);
    const [segment, setSegment] = useState([]);
    const [categorie, setCategorie] = useState([]);
    const [manufacturer, setManufacturer] = useState([]);
    const [model, setModel] = useState([]);
    const [submodel, setSubModel] = useState([]);
    const [year, setYear] = useState([]);
    const [state, setState] = useState([]);

    //Results
    //const [similar, setSimilar] = useState([]);
    //const [mostVisited, setMostVisited] = useState([]);
    const [news, setNews] = useState([]);
    const [valuesEquipment, setValuesEquipment] = useState([]);

    const [searchTxt, setSearchTxt] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSendConsulti(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'sector_id')
            getSelects('base/getsegments/', { sector_id: value });

        if (name === 'segment_id')
            getSelects('base/getcategories/', { segment_id: value });

        if (name === 'category_id')
            getSelects('base/getmanufacturers/', { category_id: value });

        if (name === 'manufacturer_id')
            getSelects('base/getmodels/', { manufacturer_id: value });

        if (name == 'model_id') {
            getSelects('base/getsubmodels/', { model_id: value });
            getSelects('base/getyears/', { model_id: value });
            getSelects('base/getstates/', { model_id: value });
        }


        if (name != 'sector_id' && name != 'segment_id' && name != 'hours') {
            var index = e.nativeEvent.target.selectedIndex;
            setNamesSelected(prevState => ({
                ...prevState,
                [name]: e.nativeEvent.target[index].text.toLowerCase()
            }));
        }

        //if (name == 'model_id')
        //searchSimilar();

        setValuesEquipment([]);
    };

    function sendSearch() {
        if (searchTxt !== null)
            navigate('/equipments?search=' + searchTxt);
        else
            navigate('/equipments');
    }

    const getSelects = async (action, params) => {
        setSearchSelect(true);

        //Resetar campos caso ocorra mudanças
        if (action === 'base/getsegments/') {
            setSendConsulti(prevState => ({
                ...prevState,
                ['segment_id']: 0,
                ['category_id']: 0,
                ['manufacturer_id']: 0,
                ['model_id']: 0,
                ['submodel_id']: 0,
                ['year']: 0,
                ['state']: ""
            }));
            setSegment([]);
            setCategorie([]);
            setManufacturer([]);
            setModel([]);
            setSubModel([]);
            setYear([]);
            setState([]);
        }

        if (action === 'base/getcategories/') {
            setSendConsulti(prevState => ({
                ...prevState,
                ['category_id']: 0,
                ['manufacturer_id']: 0,
                ['model_id']: 0,
                ['submodel_id']: 0,
                ['year']: 0,
                ['state']: ""
            }));
            setCategorie([]);
            setManufacturer([]);
            setModel([]);
            setSubModel([]);
            setYear([]);
            setState([]);
        }

        if (action === 'base/getmanufacturers/') {
            setSendConsulti(prevState => ({
                ...prevState,
                ['manufacturer_id']: 0,
                ['model_id']: 0,
                ['submodel_id']: 0,
                ['year']: 0,
                ['state']: ""
            }));
            setManufacturer([]);
            setModel([]);
            setSubModel([]);
            setYear([]);
            setState([]);
        }


        if (action === 'base/getmodels/') {
            setSendConsulti(prevState => ({
                ...prevState,
                ['model_id']: 0,
                ['submodel_id']: 0,
                ['year']: 0,
                ['state']: ""
            }));
            setModel([]);
            setSubModel([]);
            setYear([]);
            setState([]);
        }

        if (action === 'base/getsubmodels/') {
            setSendConsulti(prevState => ({
                ...prevState,
                ['submodel_id']: 0,
                ['year']: 0,
                ['state']: ""
            }));
            setSubModel([]);
            setYear([]);
            setState([]);
        }

        mutate({ method: "get", action: action, params: params }, {
            onSuccess: (res) => {
                if (action === 'base/getsegments/') {
                    setSegment(res.data);
                    setCategorie([]);
                    setManufacturer([]);
                    setModel([]);
                    setSubModel([]);
                    setYear([]);
                    setState([]);
                }

                if (action === 'base/getcategories/') {
                    setCategorie(res.data);
                    setManufacturer([]);
                    setModel([]);
                    setSubModel([]);
                    setYear([]);
                    setState([]);
                }


                if (action === 'base/getmanufacturers/') {
                    setManufacturer(res.data);
                    setModel([]);
                    setSubModel([]);
                    setYear([]);
                    setState([]);
                }


                if (action === 'base/getmodels/') {
                    setModel(res.data);
                    setSubModel([]);
                    setYear([]);
                    setState([]);
                }

                if (action === 'base/getsubmodels/')
                    setSubModel(res.data);

                if (action === 'base/getyears/')
                    setYear(res.data);

                if (action === 'base/getstates/')
                    setState(res.data);
            }
        })

        setSearchSelect(false);
    }

    const getSectors = async () => {
        mutate({ method: "get", action: "base/getsectors/" }, {
            onSuccess: (res) => {
                setSector(res.data);
            }
        })
    }


    const sendConsult = async () => {
        if (sendConsulti.model_id <= 0 || sendConsulti.year <= 0 || sendConsulti.state === "") {
            enqueueSnackbar('Você precisa preencher todos os campos!', { variant: 'error', autoHideDuration: 7000 });
            return;
        }

        mutate({ method: "get", action: "base/getequipment/", params: sendConsulti }, {
            onSuccess: (res) => {
                setValuesEquipment(res.data);
            }
        })
    }

    useEffect(() => {
        getSectors();
    }, [])
    return (
        <>
            <div className="max-h-[420px] lg:max-h-[300px] bg-cover py-2 font-outfit border-b-2 border-[#0076a2] border-solid" style={{ backgroundImage: "url(" + header + ")" }}>
                <div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8">
                    <Navbar />
                    <div className="flex justify-center items-center py-6 mb-6">
                        <div className="max-w-xl">
                            <div className="w-full text-[#f2f2f2] text-[2rem] text-center font-outfit font-bold pb-6 leading-8">O Marketplace das concessionárias de pesados do Brasil!</div>
                            <div className="flex py-4">
                                <input onChange={(res) => setSearchTxt(res.target.value)} onKeyUpCapture={(event) => (event.key === "Enter") ? sendSearch() : null} value={searchTxt} className="text-white p-3 w-full rounded-l bg-[#2c2c2c]" placeholder="O que você está procurando?" />
                                <button onClick={sendSearch} className="flex items-center gap-1 p-3 text-white rounded-r bg-gradient-to-b from-[#0086b9] to-[#006287] hover:opacity-75 transition ease-in-out delay-150">
                                    <FontAwesomeIcon icon={faSearch} />
                                    <div>Buscar</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8">
                    <div className="font-semibold text-[1.5rem] px-2 text-[#353535] mb-4">Avalie seu Equipamento</div>
                </div>
                <div className="px-2 bg-no-repeat py-[2rem] bg-cover" style={{ backgroundImage: "url(" + bannerConsulta + ")" }}>
                    <div className="flex justify-center">
                        <div className="bg-[#F5F5F5] rounded-lg p-2 w-full lg:w-7/12">
                            <div>
                                <div className="w-full text-center text-[1.2rem] font-bold text-[#012e58]">Escolha o setor</div>
                                <div className="flex flex-wrap lg:flex-nowrap justify-center gap-3 text-[#012e58]">
                                    {sector.map((item, i) => {
                                        const event = { target: { name: 'sector_id', value: item.id } };
                                        return (<div onClick={() => handleChange(event)} key={i} className={`border border-[#012e58] rounded p-1 cursor-pointer ${(sendConsulti.sector_id == item.id) ? "" : "opacity-50"}`}>
                                            <FontAwesomeIcon icon={(item.id === 1 ? faTractor : faSnowplow)} /> {item.name}</div>)
                                    })}

                                </div>
                            </div>
                            <div className="grid p-3 lg:grid-cols-2 lg:p-[2rem] gap-4 pt-4">
                                <SelectConsulti title="Segmento" name="segment_id" data={segment} change={handleChange} defaultText="Aguardando setor" loading={searchSelect} />
                                <SelectConsulti title="Categoria" name="category_id" data={categorie} change={handleChange} defaultText="Aguardando segmento" loading={searchSelect} />
                                <SelectConsulti title="Fabricante" name="manufacturer_id" data={manufacturer} change={handleChange} defaultText="Aguardando categoria" loading={searchSelect} />
                                <SelectConsulti title="Modelo" name="model_id" data={model} change={handleChange} defaultText="Aguardando fabricante" loading={searchSelect} />
                                <SelectConsulti title="Ano" name="year" data={year} change={handleChange} defaultText="Aguardando modelo" loading={searchSelect} />
                                <SelectConsulti title="Estado" name="state" data={state} change={handleChange} defaultText="Aguardando modelo" loading={searchSelect} />
                                <div>
                                    <div className="font-bold text-[#012e58] mb-2">Horímetro:</div>
                                    <InputText className="w-full p-1 px-2" placeholder="00" name="hours" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="flex items-end">
                                    <div onClick={() => { sendConsult() }} className={`flex items-center justify-center w-full p-2 bg-[#fe6100] hover:bg-[#F35F03] transition-all ease-in-out duration-500 text-white rounded uppercase text-center cursor-pointer font-bold ${(isLoading) ? 'opacity-75' : ''}`}>
                                        {(isLoading) ? <Spinner /> : 'Consultar'}
                                    </div>
                                </div>
                            </div>

                            {valuesEquipment?.length > 0 &&
                                <div className="border-t pt-2">
                                    <div className="w-full text-center text-[1.2rem] font-bold text-[#012e58] uppercase">{namesSelected.category_id} {namesSelected.manufacturer_id} {namesSelected.model_id} {(namesSelected.year > 0) && "- " + namesSelected.year}</div>
                                    <div className="grid grid-cols-2 text-[#012e58]">
                                        <div>
                                            <div className="w-full text-center text-[1.2rem] font-bold uppercase">Valor varejo:</div>
                                            <CurrencyFormat value={valuesEquipment[0]?.retail} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div className="text-center">{value}</div>} />
                                        </div>
                                        <div>
                                            <div className="w-full text-center text-[1.2rem] font-bold text-[#012e58] uppercase">Valor atacado:</div>
                                            <CurrencyFormat value={valuesEquipment[0]?.wholesale} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div className="text-center">{value}</div>} />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                {/*<div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8 mt-[2rem]">
                    <div className="px-2 pb-3">
                        {similar.length > 0 ? <>
                            <div className="font-semibold text-[1.5rem] px-2 text-[#353535]"><span className="capitalize">{namesSelected.category_id} {namesSelected.manufacturer_id}</span> relacionados</div>
                            <div className="grid lg:grid-cols-4 gap-2">
                                {(!sending) ?
                                    similar.map((item, i) => {
                                        return (
                                            <CardEquipment data={item} key={i} type="lg" />
                                        )
                                    })
                                    : [0, 1, 2, 3, 4, 5, 6, 7].map((item, i) => {
                                        return (
                                            <CardEquipmentSkeleton key={i} />
                                        )
                                    })}
                            </div>
                        </> :
                            <>
                                <div className="font-semibold text-[1.5rem] px-2 text-[#353535]">Mais visitados</div>
                                <div className="grid lg:grid-cols-4 gap-2">
                                    {(!sending) ?
                                        mostVisited.map((item, i) => {
                                            return (
                                                <CardEquipment data={item} key={i} type="lg" />
                                            )
                                        })
                                        : [0, 1, 2, 3, 4, 5, 6, 7].map((item, i) => {
                                            return (
                                                <CardEquipmentSkeleton key={i} />
                                            )
                                        })}

                                </div>
                            </>
                        }
                    </div>
                </div>*/}
                {news.length > 0 ?
                    <div className="bg-gradient-to-b from-[#0086b9] to-[#006287]">
                        <div className="mx-auto max-w-6xl px-4 lg:px-6 lg:px-8 py-4">
                            <div className="font-semibold text-[1.3rem] text-white">Notícias sobre o Agronegócio</div>
                            <div className="grid lg:grid-cols-3 gap-6 lg:gap-4 py-2">
                                {news.map((item, i) => {
                                    return (
                                        <CardNews data={item} key={i} />
                                    )
                                })}

                            </div>
                        </div>
                    </div> : null}
                <div className="py-4 flex justify-center items-center">
                    <img src={banner} className="h-[40px] md:h-[120px]" />
                </div>
                <div className="bg-gradient-to-b from-[#0086b9] to-[#006287]">
                    <div className="flex flex-wrap justify-center items-center py-4 gap-3">
                        <div className="text-white text-[1.2rem]">Receba novos lotes</div>
                        <div className="flex justify-center items-center gap-2 relative">
                            <input className="text-[15px] text-[#000] px-3 p-[12px] w-full rounded " placeholder="Cadastre seu e-mail..." />
                            <button className="text-[15px] uppercase right-[-3rem] lg:right-[-5rem] absolute bg-[#004059] text-white p-[12px] rounded-lg">Cadastrar</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    );
}

export default Consulti;
