import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export const AuthContext = React.createContext({});

export const AuthProvider = (props) => {
    const navigate = useNavigate();
    const [user, setUser] = React.useState(null);
    const cookies = new Cookies();
    useEffect(() => {
        const verify = async () => {
            const cookie = cookies.get('authorization');

            if (cookie) {
                const userData = cookie;
                setUser({ id: jwtDecode(userData.token).id, name: jwtDecode(userData.token).name, email: jwtDecode(userData.token).email, token: userData.token, approved: jwtDecode(userData.token).approved_outlet });
            } else {
                setUser(null);
                cookies.remove('authorization', { path: '/' });
                if (window.location.pathname == '/register-step-2')
                    navigate('/login');
            }
        }
        verify();

    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => React.useContext(AuthContext);