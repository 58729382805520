import { Helmet } from "react-helmet";

export default function MetaPixel(props) {
    function htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
      }

    let price = props.data.price;
    let title = props.data?.name?.charAt(0).toUpperCase() + props.data?.name?.slice(1).toLowerCase();
    const description = (props.data?.description?.length > 2) ? htmlDecode(props.data?.description?.replace(/<[^>]*>?/gm, '')) : title;
    return (
        <Helmet>
        <meta property="og:title" content={title}></meta>
        <meta property="og:description" content={description}></meta>
        <meta property="og:url" content={`https://outlet.viamaquinas.com.br/equipment/${props.data.id}`}></meta>
        <meta property="og:image" content={`https://viasgd.com.br/Assets/Produtos/${props.data.id}/${props.data.cover_photo}`}></meta>
        <meta property="product:brand" content={props.data.manufacturer}></meta>
        <meta property="product:availability" content="in stock"></meta>
        <meta property="product:condition" content="used"></meta>
        <meta property="product:price:amount" content={price?.replaceAll(',', '')}></meta>
        <meta property="product:retailer_item_id" content={props.data.id}></meta>
        <meta property="product:price:currency" content="BRL"></meta>
        </Helmet>
    );
}