import axios from "axios";
import Cookies from "universal-cookie";
import { useAuth } from "../providers/auth";

const instance = axios.create({
  baseURL: "https://api.outlet.viamaquinas.com.br",
  timeout: process.env.REACT_APP_REQUEST_TIMEOUT
});
console.log(instance);
export const useHttp = () => {
  const cookies = new Cookies();

  const { user, setUser } = useAuth();
  
  const cookie = cookies.get("authorization");
  const bearerToken = cookie ?
    "bearer " + cookie.token :
    "";

  const request = async (configObject) => {
    const { url, method, data } = configObject;
    
    const options = {
      url: url,
      method: method.toLowerCase(),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": bearerToken,
        "Token-Access": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoib3V0bGV0In0.Kr__Ghw_31JkuRN2NKy6JPKEXHNhKOpnTtpUSJNE_Mk"

      },
      data: data
    };
    
    try {
      const response = await instance(options);
      
      return response;
    } catch (e) {
      let error = new Error();

      Object.assign(error, { response: e.response });
      //console.log(error.response)
      throw error;
    }
  }

  return request;
}