import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import logo from "../assets/img/new_logo.png";
import header from "../assets/img/header_outlet.png";
import Footer from '../components/Layout/Footer';
import InputText from "../components/Form/InputText";
import Spinner from "../components/Spinner";
import { useSnackbar } from "notistack";
import Cookies from 'universal-cookie';
import Navbar from "../components/Layout/Navbar";
import registerSchema from "../utils/schemas/register.schema";
import Button from "../components/Form/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useRegister } from "../utils/services/queries";
import InputTextMask from "../components/Form/InputTextMask";

const Register = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const cookies = new Cookies();
    const {
        reset,
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(registerSchema) });

    const { mutate, isLoading, data: responseData, error } = useRegister();

    const registerAccount = (data) => {
        mutate(data);
        reset();
    };

    if (responseData?.status === 200) {
        cookies.set("authorization", responseData.data, {
            maxAge: Number(responseData.data.expires_in) * 60,
        });
        navigate("/register-step-2");
    }

    useEffect(() => {
        if (error?.response?.status === 401)
            enqueueSnackbar("E-mail ou senha incorretos!", { variant: 'error' });

        if (error?.response?.status === 400)
            enqueueSnackbar("E-mail já cadastrado!", { variant: 'error' });

    }, [error])



    return (
        <>
            <div className="max-h-[100px] lg:max-h-[80px] bg-cover py-2 font-outfit border-b-2 border-[#0076a2] border-solid" style={{ backgroundImage: "url(" + header + ")" }}>
                <div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8">
                    <Navbar />
                </div>
                <div className="bg-gray-50 min-h-[calc(100vh-57px)] flex flex-col items-center justify-center p-5">
                    <div className="max-w-sm bg-white rounded-lg shadow-sm py-4 px-6 rounded-lg p-2 w-full lg:w-7/12">

                        <h1 className="text-xl font-bold text-gray-700 mb-1">Crie sua conta</h1>
                        <span className="text-gray-700 block mb-5 text-sm">
                            Já tem uma conta?{" "}
                            <Link
                                to="/login"
                                className="font-medium hover:underline text-orange-500"
                            >
                                entre aqui
                            </Link>
                            .
                        </span>
                        <form className="pt-4" onSubmit={handleSubmit(registerAccount)}>
                            <div className="flex flex-col md:flex-row gap-3">
                                <label className="w-full h-10 flex flex-row items-center pl-3 rounded-md border-2 border-gray-100 cursor-pointer">
                                    <input
                                        type="radio"
                                        name="type"
                                        value="F"
                                        {...register("type")}
                                        className="inline-block mr-2"

                                    />
                                    <span className="text-md font-gray-700">Pessoa física</span>
                                </label>

                                <label className="w-full h-10 flex flex-row items-center pl-3 rounded-md border-2 border-gray-100 cursor-pointer">
                                    <input
                                        type="radio"
                                        name="type"
                                        value="J"
                                        {...register("type")}
                                        className="inline-block mr-2"

                                    />
                                    <span className="text-md font-gray-700">Pessoa jurídica</span>
                                </label>
                            </div>
                            <div className="mb-3">
                                {errors.type && (
                                    <p className="mt-1 text-red-600 text-sm font-normal">
                                        {errors.type.message}
                                    </p>
                                )}
                            </div>
                            <InputText
                                disabled={isLoading}
                                placeholder="Nome completo"
                                name="name"
                                register={register}
                                error={errors.name}
                            />

                            <InputTextMask
                                disabled={isLoading}
                                placeholder="Telefone para contato"
                                name="phone_number1"
                                register={register}
                                mask={"(99) 99999-9999"}
                                setValue={setValue}
                                error={errors.phone_number1}
                            />

                            <InputTextMask
                                disabled={isLoading}
                                placeholder="CPF ou CNPJ"
                                name="cpf_cnpj"
                                register={register}
                                mask="cpf_cnpj"
                                setValue={setValue}
                                error={errors.cpf_cnpj}
                            />

                            <InputText
                                disabled={isLoading}
                                placeholder="E-mail"
                                name="email"
                                register={register}
                                error={errors.email}
                            />

                            <InputText
                                disabled={isLoading}
                                placeholder="Senha de acesso"
                                name="password"
                                register={register}
                                error={errors.password}
                                type="password"
                            />

                            <label className="checkbox-label">
                                <span className="inline-block font-normal text-gray-800 text-sm">
                                    Sim, li todo o{" "}
                                    <Link href="" className="hover:underline text-orange-400">
                                        contrato
                                    </Link>{" "}
                                    e concordo plenamente com todos os{" "}
                                    <Link href="" className="hover:underline text-orange-400">
                                        termos de adesão de usuários
                                    </Link>{" "}
                                    para o acesso ao leilão online do portal Usadão Máquinas.
                                </span>
                                <input type="checkbox" name="accept" {...register("accept")} />
                                <span className="checkmark"></span>
                            </label>
                            <div className="mb-3">
                                {errors.accept && (
                                    <p className="mt-1 text-red-600 text-sm font-normal">
                                        {errors.accept.message}
                                    </p>
                                )}
                            </div>

                            <Button isLoading={isLoading}>Criar nova conta</Button>
                        </form>


                    </div>

                </div>

                <Footer />
            </div>

        </>
    );
}

export default Register;
