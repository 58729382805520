import jwtDecode from "jwt-decode";
import { useHttp } from "../hooks/useHttp";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../providers/auth";
import { buildParams } from "../lib/utils";
import Cookies from "universal-cookie";


export const useStates = () => {
    const request = useHttp();

    const result = useQuery({ queryKey: ["states"], queryFn: () => request({ url: "/location/state", method: "GET" }), refetchOnWindowFocus: false });

    return result;
}

// pegar as cidades
export const useCities = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: (stateId = 1) => request({ url: "/location/city?state=" + stateId || "", method: "GET", data: {} })
    });

    return mutation;
}

// pegar os dados dos usuários
export const useUserInfo = () => {
    const request = useHttp();

    const { data, isLoading } = useQuery({ queryKey: ["info"], queryFn: () => request({ url: "/cliente/info", method: "GET" }), refetchOnWindowFocus: false });

    return { data, isLoading };
}

// update user
export const useUserApproved = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/cliente/approved", method: "GET", data: { ...data, en_country_id: 30 } })
    });

    return mutation;
}


// login
export const useLogin = () => {
    const request = useHttp();

    const { setUser } = useAuth();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/login", method: "post", data: data }),
        onSuccess: (response) => {
            if (response.status === 200) {
                setUser({
                    id: jwtDecode(response.data.token).id,
                    name: jwtDecode(response.data.token).name,
                    email: jwtDecode(response.data.token).email,
                    token: response.data.token,
                    approved: jwtDecode(response.data.token).approved
                });
            }

        }
    });

    return mutation;
}

// update user
export const useUserUpdate = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/cliente/info", method: "POST", data: { ...data, en_country_id: 30 } })
    });

    return mutation;
}

// cadastro
export const useRegister = () => {
    const request = useHttp();

    const { setUser } = useAuth();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/register", method: "post", data: data }),
        onSuccess: (response) => {
            if (response.status === 200) {
                setUser({
                    id: jwtDecode(response.data.token).id,
                    name: jwtDecode(response.data.token).name,
                    email: jwtDecode(response.data.token).email,
                    token: response.data.token
                });
            }
        }
    });

    return mutation;
}

export const useProducts = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/produtos?" + buildParams(data), method: "GET" })
    });

    return mutation;
}

export const useProductInfo = (id) => {
    const request = useHttp();

    const { data, isLoading } = useQuery({ queryKey: ["product-info"], queryFn: () => request({ url: `/produto/${id}`, method: "GET" }), refetchOnWindowFocus: false });

    return { data, isLoading };
}

export const useProductsFilter = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/produtos/filtros?" + buildParams(data), method: "GET" })
    });

    return mutation;
}

export const useContact = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/contact", method: "POST", data: { ...data } })
    });

    return mutation;
}

export const useOffer = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/produto/oferta", method: "POST", data: { ...data } })
    });

    return mutation;
}

export const useConsulti = () => {
    const request = useHttp();

    const mutation = useMutation({
        mutationFn: data => request({ url: "/admin/consulti/acess", method: "POST", data: { ...data } })
    });

    return mutation;
}

export const useVisiteds = () => {
    const request = useHttp();

    const cookies = new Cookies();

    const equipments_visiteds = cookies.get('equipments_visited') ? cookies.get('equipments_visited') : [0];

    const { data, isLoading } = useQuery({ queryKey: ["equipment-visiteds"], queryFn: () => request({ url: `/produtos/selecionados?${equipments_visiteds.map((n, index) => `id[${index}]=${n}`).join('&')}`, method: "GET" }), refetchOnWindowFocus: false });

    return { data, isLoading };
}