export default function Modal({ isVisible, onClose, children }) {
    if (!isVisible) return;

    const handleClose = (e) => {
        if (e.target.id === "wrapper") onClose();
    }
    return (
        <div id="wrapper" onClick={handleClose} className="fixed top-0 left-0 right-0 z-50 bg-black min-h-screen bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 md:w-2/5 sm:w-3/5">
                <div className="relative w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow ">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}