import React, { useEffect, useState, useContext } from "react";
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";
import logo from "../../assets/img/VIARENTALFUNDOBRANCO2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Contact from "../Contact";
import LoginButton from "./Navbar/LoginButton";

export default function Header(props) {
  const navigate = useNavigate();
  const [showContact, setShowContact] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [width, setWidth] = useState(window.innerWidth);
  const [searchTxt, setSearchTxt] = useState('');
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  function openModal() {
    props.setModal(true);
  }

  function sendSearch() {
    if (searchTxt !== null)
      navigate('/equipments?search=' + searchTxt);
    else
      navigate('/equipments');
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  return (
    <nav className="navbar navbar-light navbar-fixed shadow-lg" style={{ background: 'black', borderBottom: '1px solid rgb(243 245 249 / 25%)' }}>
      <div className="mx-auto max-w-6xl py-3 px-6 lg:px-0">
        <div className="flex justify-between items-center">
          <div className="cursor-pointer" onClick={() => navigate('/')}><img src={logo} className="h-[70px]" /></div>
          {/*<div className="w-3/6 hidden sm:flex py-4">
            <input onChange={(res) => setSearchTxt(res.target.value)} onKeyUpCapture={(event) => (event.key === "Enter") ? sendSearch() : null} className="text-white p-3 w-full rounded-l bg-[#082c60]" placeholder="O que você está procurando?" />
            <button onClick={sendSearch} className="flex items-center gap-1 p-3 text-white rounded-r bg-gradient-to-b from-[#0086b9] to-[#006287] hover:opacity-75 transition ease-in-out delay-150">
              <FontAwesomeIcon icon={faSearch} />
              <div>Buscar</div>
            </button>
          </div>
          <div className="text-white md:block hidden">
            <LoginButton mode="static" />
          </div>*/}
          {props.filter ?
            <div className="md:hidden block pr-2">
              <IconButton aria-label='tune' onClick={openModal}>
                <TuneIcon sx={{ color: '#FFF' }} />
              </IconButton>
            </div> : ''}
          <Contact show={showContact} setShow={setShowContact} type="contato" />
        </div>
      </div>
    </nav >
  )
}