import Spinner from "../Spinner";

export default function SelectConsulti(props) {
    const { title, name, data, defaultText, loading } = props;
    return (
        <div>
            <div className="font-bold text-[#012e58] mb-2">{title}:</div>
            {loading && data?.length === 0 ?
                <div className="w-full bg-white rounded p-1 px-3">
                    <Spinner className="text-[#000]" />
                </div> :
                <select className="w-full p-1 rounded" name={name} onChange={(e) => { props.change(e) }}>
                    {data?.length > 0 && <option value="0">Selecione uma opção</option>}
                    {data?.length > 0 ?
                        data?.map((item, i) => {
                            if (name === 'year') {
                                item.id = item.year;
                                item.name = item.year;
                            }
                            if (name === 'state')
                                item.id = item.name;

                            return (<option key={i} value={item.id}>{item.name}</option>)
                        })
                        : <option>{defaultText}</option>}
                </select>}
        </div>
    )
}