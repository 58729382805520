export default function putIfNull(value) {
    if (value === null || value === undefined)
        return "";
    else
        return value;
}

function buildParams(data) {
    const params = new URLSearchParams()

    Object.entries(data).forEach(([key, value]) => {

        if (Array.isArray(value)) {
            if (!value.includes(undefined))
                value.forEach(value => params.append(key + '[]', value?.toString()))
        } else {
            if (value !== null)
                params.append(key, value.toString())
        }
    });

    return params?.toString();
}

export {
    buildParams
};