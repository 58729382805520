import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Badge from '@mui/material/Badge';
import logoBranca from "../../assets/img/VIARENTALFUNDOBRANCO2.png";
import Contact from "../Contact";
import { useState } from "react";

export default function Footer(props) {
    const [showContact, setShowContact] = useState(false);
    let contactText = 'Olá! Vim através do outlet, gostaria de mais informações!';
    contactText = encodeURIComponent(contactText);
    return (

        <div className="bg-[#3a3a3a] text-[#f5ac29] w-full">
            <div className="bg-gradient-to-b from-[#f5ac29] to-[#D69623] mt-8 w-full">
                <div className="flex flex-wrap justify-center items-center py-4 gap-3">
                    <div className="text-black text-[1.2rem]">Fale conosco <span className="font-bold">agora mesmo!</span></div>
                    <div className="flex justify-center items-center gap-2 relative">
                        <button className="text-[15px] uppercase ml-[150px] absolute bg-[#616c68] text-white p-[12px] rounded-lg" onClick={() => window.open('https://wa.me/5547999371903')}>Contato</button>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 py-4">
                <div className="grid sm:grid-cols-3 pb-3 text-center gap-2 sm:text-left">
                    <div className="flex justify-center sm:block"><img src={logoBranca} className="h-[100px]" /></div>
                    <div className="list-none">
                        <div className="font-semibold pb-1">Sobre nós</div>
                        <li><Link onClick={() => window.open('https://wa.me/5547999371903')} className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Contate-nos</Link></li>
                        <li><Link to="/terms/privacy" target="_blank" className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Política de Privacidade</Link></li>
                        <li><Link to="/terms/cookies" target="_blank" className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Política de Cookies</Link></li>
                    </div>
                    <div className="list-none">
                        <div className="font-semibold pb-1">Outros Produtos</div>
                        <li><Link to={{ pathname: "//viasgd.com.br/Concessionaria.php" }} target="_blank" className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Via SGD</Link></li>
                        <li><Link to={{ pathname: "//viaconsulti.com.br" }} target="_blank" className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Via Consulti</Link></li>
                        <li><Link to={{ pathname: "//conseguros.com.br" }} target="_blank" className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Via Conseguros</Link></li>
                        <li><Link to={{ pathname: "//usadaomaquinas.com.br" }} target="_blank" className="no-underline text-[#FFF] text-[14px] hover:text-[#006c99] transition ease-in-out duration-500">Usadão Máquinas</Link></li>
                    </div>
                </div>
                <div className="text-center text-[13px] pt-5 font-light opacity-95">
                    Via Comércio de Máquinas e Equipamentos LTDA CNPJ 11.280.481/0001-52 | Rua Uganda, 151 | Bloco Auster | Sala 13 | Bairro Nações | CEP 88338-160 | Balneário Camboriú | SC | Brasil
                    Grupo Via Máquinas. Todos os Direitos Reservados.
                </div>
            </div>
            <Link href={`https://wa.me//554796439730?text=${contactText}`} className="text-[#FFF]">
                <div className="fixed cursor-pointer right-[2rem] z-20 bottom-[1rem] bg-[#008614] rounded-[50%] p-[.5rem] hover:opacity-75 transition-all ease-in-out duration-500">
                    <Badge badgeContent={4} color="error">
                        <WhatsAppIcon style={{ fontSize: 40 }} />
                    </Badge>
                </div>
            </Link>
            <Contact show={showContact} setShow={setShowContact} type="contato" />
        </div>
    )
}