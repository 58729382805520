import Cookies from 'universal-cookie';
import React, { useEffect } from "react";
import header from "../assets/img/header_outlet.png";
import Footer from '../components/Layout/Footer';
import Navbar from '../components/Layout/Navbar';
import RegisterPJ from '../components/RegisterPJ';
import { useUserInfo } from '../utils/services/queries';
import RegisterPF from '../components/RegisterPF';

export default function RegisterTwo({ cookie }) {
    const { data, isLoading } = useUserInfo();


    return (
        <>
            <div className="max-h-[100px] lg:max-h-[80px] bg-cover py-2 font-outfit border-b-2 border-[#0076a2] border-solid" style={{ backgroundImage: "url(" + header + ")" }}>
                <div className="mx-auto max-w-6xl px-2 lg:px-6 lg:px-8">
                    <Navbar />
                </div>
                {isLoading &&
                    <div className="w-full flex flex-col items-center min-h-[calc(100vh-57px)] pb-10 bg-gray-50">
                        <div className="w-full max-w-[1120px] mx-auto px-5 flex items-center justify-center">
                            <div className="text-gray-800 mt-5 md:mt-10">
                                <h1 className="text-md text-center font-medium text-orange-500">Aguarde! 🤠</h1>
                                <h1 className="text-sm">Carregando informações...</h1>
                            </div>
                        </div>
                    </div>}
                {data?.data.type === 'J'
                    ? <RegisterPJ /> : <RegisterPF />}
                <Footer />
            </div>

        </>
    )

}

export { default as getServerSideProps } from "../utils/lib/serverProps";