import * as yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];

const registerStepTwoPJ = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  //ie_sintegra: yup.string().required("Campo obrigatório."),
  //rg_ie: yup.string().required("Campo obrigatório."),
  cpf_cnpj: yup.string().required("Campo obrigatório."),
  phone_number1: yup.string().required("Campo obrigatório."),
  username: yup.string().required("Campo obrigatório."),
  email: yup.string().email("Preencha um e-mail válido.").required("Campo obrigatório."),
  en_cep: yup.string().required("Campo obrigatório."),
  en_address: yup.string().required("Campo obrigatório."),
  en_number: yup.string().required("Campo obrigatório."),
  en_complement: yup.string().required("Campo obrigatório."),
  en_district: yup.string().required("Campo obrigatório."),
  en_city_id: yup.string().required("Campo obrigatório."),
  en_state_id: yup.string().required("Campo obrigatório."),
  identity: yup.mixed()
    .nullable()
    .required("É necessário enviar o arquivo!")
    .test("hasFile", "É necessário enviar o arquivo!", (file) => {
      return file.length <= 0 ? false : true;
    })
    .test("format",
      "Formato inválido! formatos suportados: jpg, jpeg, png, pdf.", (value) => {
        if (value.length > 0) {
          return SUPPORTED_FORMATS.includes(value[0].type);
        }
      }),
  declaration: yup.mixed()
    .nullable()
    .required("É necessário enviar o arquivo!")
    .test("hasFile", "É necessário enviar o arquivo!", (file) => {
      return file.length <= 0 ? false : true;
    })
    .test("format",
      "Formato inválido! formatos suportados: jpg, jpeg, png, pdf.", (value) => {
        if (value.length > 0) {
          return SUPPORTED_FORMATS.includes(value[0].type);
        }
      })
}).required();

export default registerStepTwoPJ;