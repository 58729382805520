import { useState } from 'react';
import InputMask from 'react-input-mask';
export default function InputTextMask({ register, setValue, name, mask, error, placeholder, disabled }) {
    const [inputValue, setInputValue] = useState("");

    return (
        <>
            <InputMask
                {...(register !== undefined) ? register(name) : null}
                maskChar=""
                mask={
                    (mask === "cpf_cnpj") ?
                        inputValue?.length > 14
                            ? "99.999.999/9999-99"
                            : "999.999.999-999" : mask
                } disabled={disabled}
                name={name}
                onChange={(e) => { setInputValue(e.target.value) || setValue(name, e.target.value) }}>
                {(inputProps) => <input placeholder={placeholder} className="text-black darK:text-white disabled:opacity-80 disabled:bg-white border-2 border-gray-100 rounded-md h-10 block w-full px-3 focus:border-orange-500 focus:outline-none placeholder:text-gray-300 focus:shadow-input-custom" />}
            </InputMask>
            <div className="mb-4">
                {error && (
                    <p className="mt-1 text-red-600 text-sm font-normal">
                        {error.message}
                    </p>
                )}
            </div>
        </>
    )
}