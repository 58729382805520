import React from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';

export default function Filter({ filter, loading, filterChange, subcategoryFilter, manufacturerFilter, modelFilter, ...props }) {
  const filterLoad = [];

  for (let i = 1; i < 10; i++) {
    filterLoad.push(<Skeleton key={i} animation="wave" variant="text" sx={{ fontSize: '1rem' }} />);
  }

  return (
    <>
      <h6 className="my-2">Categorias</h6>
      <div className="custom__scroll filter-category max-h-[200px] relative overflow-auto my-5">
        {loading && filterLoad}
        {filter?.subcategorie?.length > 0 ?
          filter?.subcategorie?.map((type, index) => (
            <FormGroup key={index}>
              <FormControlLabel
                onChange={filterChange} control={
                  <Checkbox checked={(subcategoryFilter.indexOf(type.ids.toString()) > -1) ? true : false} color="default" size="small" inputProps={{ 'data-filter': 'subcategory', 'data-filter-id': type.ids }} />}
                label={type.name}
                sx={
                  { '& .MuiFormControlLabel-label': { fontSize: 14 } }
                } />
            </FormGroup>

          )) : <>{!loading && <p className="default-filter">Sem resultados para essa categoria.</p>}</>}
      </div>
      <hr className="line"></hr>

      <h6 className="my-2">Marcas</h6>
      <div className="custom__scroll filter-brand max-h-[200px] relative overflow-auto my-5">
        {loading && filterLoad}
        {filter?.manufacture?.length > 0 ?
          filter?.manufacture?.map((type, index) => (
            <FormGroup key={index}>
              <FormControlLabel onChange={filterChange}
                control={
                  <Checkbox checked={(manufacturerFilter.indexOf(type.ids.toString()) > -1) ? true : false} color="default" size="small" inputProps={{ 'data-filter': 'manufacturer', 'data-filter-id': type.ids }} />}
                label={type.name}
                sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }} />
            </FormGroup>

          )) : <>{!loading && <p className="default-filter">Sem resultados para essa categoria.</p>}</>}
      </div>

      <hr className="line"></hr>
      <h6 className="my-2">Modelos</h6>
      <div className="custom__scroll filter-model max-h-[200px] relative overflow-auto my-5">
        {loading && filterLoad}
        {filter?.model?.length > 0 ?
          filter?.model.map((type, index) => (
            <FormGroup key={index}>
              <FormControlLabel onChange={filterChange}
                control={<Checkbox checked={(modelFilter.indexOf(type.ids.toString()) > -1) ? true : false} color="default" size="small" inputProps={{ 'data-filter': 'model', 'data-filter-id': type.ids }} />} label={type.name} sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }} />
            </FormGroup>

          )) : <>{!loading && <p className="default-filter">Sem resultados para essa categoria.</p>}</>}
      </div>
    </>
  )
}