import logo from "../../assets/img/VIARENTALFUNDOBRANCO2.png";
import { Link, useNavigate } from 'react-router-dom';
import LoginButton from "./Navbar/LoginButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { LogOut, UserCircle, ChevronDown,AlignJustify } from "lucide-react";
import { useState } from "react";

export default function Navbar(props) {
    const { isMobile } = props
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
    if (!isMobile) {


        return (
            <div className="flex flex-row justify-center absolute top-4 w-full">
                <img src={logo} className="h-[80px] mt-1 cursor-pointer" onClick={() => navigate('/#')} />
                {/*<div className="flex flex-wrap w-1/4 font-nunito font-semibold lg:flex-nowrap lg:gap-10">
                    <div className="hidden sm:flex py-4 w-full">
                        <input className="text-white p-3 rounded-l-full bg-white bg-opacity-30 w-5/6 " placeholder="O que você está procurando?" />
                        <button className="flex items-center gap-1 p-3 text-[#f5ac29] rounded-r-full bg-white no-border bg-opacity-30 hover:opacity-75 transition ease-in-out delay-150">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>
                <div className="text-white flex flex-row md:block pt-6 items-center">
                    <span className="px-2">Alugar<ChevronDown className="text-[#f5ac29] inline-block mr-1 p-[3px]" /></span>
                    <span className="px-2 ">Contato</span>
                    <span className="px-2 ">Ajuda</span>
                    <span className="px-1">
                        <UserCircle className="text-[#f5ac29] inline-block mr-1 p-[3px]" />
                        Entrar
                    </span>
                </div>*/}
            </div>
        )
    } else {  
        return (
            <div className="flex flex-row justify-between absolute top-4 w-full">
              {/* Logo */}
              <div>
                <img
                  src={logo}
                  className=" h-[80px] mt-1 cursor-pointer"
                  onClick={() => navigate('/#')}
                />
              </div>
        
              {/* Menu Mobile */}
              <div className="flex items-center mr-2">
                <button
                  className="text-white font-bold text-lg p-2 rounded-full bg-white bg-opacity-0 hover:opacity-75 transition ease-in-out delay-150"
                  onClick={toggleMenu}
                >
                  <span className="sr-only">Menu</span>
                  {/* Adicione um ícone de 3 linhas para o menu */}
                  <AlignJustify/>
                </button>
        
                {/* Menu Dropdown */}
                {showMenu && (
                  <div className="absolute right-4 top-16 bg-white shadow-md rounded-md">
                    <ul className="p-4">
                      <li className="py-2">
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          Alugar
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          Contato
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          Ajuda
                        </a>
                      </li>
                      <li className="py-2">
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          Entrar
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          );
    }
}