import { Link } from "react-router-dom";

export default function CardNews(props) {
    const { data } = props;
    return (
        <div className="bg-white rounded-lg drop-shadow-md relative">
            <div className="rounded-t-lg min-h-[250px] sm:min-h-[250px] bg-cover bg-no-repeat" style={{ backgroundImage: "url(" + data?.image + ")" }}></div>
            <div className="px-3 py-2 pb-3 min-h-[200px]">
                <div className="font-semibold text-[14px] pb-2">{data?.title}</div>
                <div className="text-[14px] pb-2 opacity-75">
                    {data?.content.length > 406 ?
                        `${data?.content.substring(0, 406)}...` : data?.content
                    }
                </div>
            </div>
            <div className="flex items-center justify-center">
                <Link to={"//" + data?.url} className="absolute bottom-[-1rem] cursor-pointer bg-gradient-to-b from-[#0086b9] to-[#006287] text-white py-1 px-3 rounded-lg uppercase text-[13px] transition-all ease-in-out duration-500 hover:mt-[-7px] hover:opacity-75">Ver mais</Link>
            </div>
        </div>

    );
}