import './App.css';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from './pages/Home';
import Equipments from "./pages/Equipments";
import Equipment from "./pages/Equipment";
import React, { useState } from 'react';
import Privacy, { Conditions, Cookies } from './pages/Terms';
import Special from './pages/Special';
import Consulti from './pages/Consulti';
import Register from './pages/Register';
import RegisterTwo from './pages/RegisterTwo';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from './utils/providers/auth';
import Login from './pages/Login';
import RegisterFinished from './pages/RegisterFinished';

// Create a client
const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState({});

  return (
    <BrowserRouter>

      <main>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register-step-2" element={<RegisterTwo />} />
              <Route path="/register-finished" element={<RegisterFinished />} />
              <Route path="/consulti" element={<Consulti />} />
              <Route path="/equipments" element={<Equipments />} />
              <Route path="/oportunidade/:id" element={<Special />} />
              <Route path="/equipment/:id" element={<Equipment />} />
              <Route path="/terms/privacy" element={<Privacy />} />
              <Route path="/terms/cookies" element={<Cookies />} />
              <Route path="/terms/conditions" element={<Conditions />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </QueryClientProvider>
        </AuthProvider>
      </main>
    </BrowserRouter>
  );
}

export default App;
